import { React } from "react";
import "./Overlay.css";

export const Overlay = ({ onClose, children }) => {
  return (
    <div className="overlay-container" onClick={() => onClose()}>
      {children}
    </div>
  );
};
