import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { React, useState } from "react";
import "./DropdownField.css";

export const DropdownField = ({
  label_name,
  name,
  type,
  value,
  options,
  placeholder,
  formik,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const changeTheFormikValue = (value) => {
    formik.setFieldValue(name, value);
    setOpenDropdown(false);
  };
  return (
    <div className="dropdown-field-container">
      {label_name && <label>{label_name}</label>}
      <div
        className="dropdown-field-wrapper"
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={formik.handleChange}
          readOnly
        />

        {options && (
          <div>
            {!openDropdown ? (
              <MdExpandMore></MdExpandMore>
            ) : (
              <MdExpandLess></MdExpandLess>
            )}
          </div>
        )}

        {options && openDropdown && (
          <div className="dropdown-field-options">
            {options?.map((element) => {
              return (
                <div onClick={() => changeTheFormikValue(element)}>
                  {element}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
