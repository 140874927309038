import React, { useEffect, useMemo, useState } from "react";
import deleteIcon from "./icons/delete.svg";
import linkedin from "./icons/linkedin.png";
import twitter from "./icons/twitter.png";
import instagram from "./icons/instagram.png";
import facebook from "./icons/facebook.png";
import youtube from "./icons/youtube.png";
import tiktok from "./icons/tiktok.png";
import whatsapp from "./icons/whatsapp.png";
import telegram from "./icons/telegram.png";
import "./Links.css";
import axios from "../../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import toastStyle from "../../../../configs/toastConfig";

const Links = ({ socialLinks, setMarketersdata }) => {
  console.log("the social links are ", socialLinks);
  const [isAddNewLinkOpen, setIsAddNewLinkOpen] = useState(false);
  const [linkTitle, setLinkTitle] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const socialMediaLinks = [
    {
      id: 1,
      name: "linkedIn",
      icon: linkedin,
      placeholderName: "LinkedIn",
    },
    {
      id: 2,
      name: "xProfile",
      icon: twitter,
      placeholderName: "X profile",
    },
    {
      id: 3,
      name: "instagram",
      icon: instagram,
      placeholderName: "Instagram",
    },
  ];

  const [linkObj, setLinkObj] = useState(socialLinks);
  // const updatedContent = useMemo(() => ({}), []);
  const [errors, setErrors] = useState([]);
  const [otherLinks, setOtherLinks] = useState(socialLinks.otherLinks);
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const [saveText, setSaveText] = useState("Save");

  //  local storage on component mount
  useEffect(() => {
    const savedLinks = JSON.parse(localStorage.getItem("savedLinks"));
    if (savedLinks) {
      setLinkObj(savedLinks.linkObj);
      setOtherLinks(savedLinks.otherLinks);
    }
  }, []);
  //  local storage whenever they are updated
  useEffect(() => {
    localStorage.setItem("savedLinks", JSON.stringify({ linkObj, otherLinks }));
  }, [linkObj, otherLinks]);

  function handleAddNewLink() {
    console.log({
      linkTitle: linkTitle,
      linkUrl: linkUrl,
    });

    console.log("the other links are ", otherLinks);
    setOtherLinks((prev) => [
      ...prev,
      {
        linkTitle: linkTitle,
        linkUrl: linkUrl,
      },
    ]);
    setIsAddNewLinkOpen(false);
    setLinkTitle("");
    setLinkUrl("");
  }

  function handleDeleteLink(id) {
    console.log(id);
    const filteredLinks = otherLinks.filter((link) => link._id !== id);
    setOtherLinks(filteredLinks);
  }
  const regexPatterns = {
    linkedIn:
      /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/,
    xProfile: /^((https?:\/\/)?(www\.)?x\.com\/)([a-zA-Z0-9_.-]+\/?)$/,
    instagram: /^((https?:\/\/)?(www\.)?instagram\.com\/)([a-zA-Z0-9_.-]+\/?)$/,
  };

  const onChangeValueofLink = async (e) => {
    const { name, value } = e.target;
    const regex = regexPatterns[name];

    if (regex && regex.test(value)) {
      setLinkObj((prev) => ({ ...prev, [name]: value }));
      if (errors.includes(name)) {
        setErrors((prevErrors) => prevErrors.filter((error) => error !== name));
      }
    } else if (value === "") {
      delete linkObj[name];
      if (errors.includes(name)) {
        setErrors((prevErrors) => prevErrors.filter((error) => error !== name));
      }
    } else {
      if (!errors.includes(name)) {
        setErrors((prevErrors) => [...prevErrors, name]);
      }
    }
  };

  useEffect(() => {
    if (saveText === "Saved") {
      setTimeout(() => {
        setSaveText("Save");
      }, 3000);
    }
  }, [saveText]);

  useEffect(() => {
    console.log(linkObj, "linkObj");
  }, [linkObj]);

  const updateUserLinks = async (e) => {
    e.preventDefault();
    console.log("the errors are ", errors);
    setSaveText("Saved");

    if (errors.length !== 0) {
      toast.error(
        `Please enter valid links for ${errors.join(",")}`,
        toastStyle
      );
    } else {
      toast.success("Links Updated Successfully", toastStyle);
      let socialLinks = {
        ...linkObj,
        otherLinks: otherLinks,
      };
      let updateMarketer = await axios.post(
        "/marketers/updateMarketer",
        { socialLinks },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      setLinkObj(updateMarketer?.data?.data?.socialLinks);
      setMarketersdata(updateMarketer?.data?.data);
    }
  };

  const removeOptionLink = (id, name) => {
    const remains = otherLinks?.filter((link) => link._id !== id);
    toast.success(`${name} link removed successfully!`, toastStyle);
    setOtherLinks(remains);
  };

  return (
    <main className="links_main_container">
      <form className="links_form formScrollBarDisplay">
        <div className="socialMediaProfile">
          <div className="linksContainer">
            {socialMediaLinks?.map((link) => (
              <div key={link.id} className="linkDiv">
                <div className="linkIconAndImage">
                  {/* <img src={link.icon} alt={link.name} /> */}
                  <p className="linkIconName">{link.placeholderName}</p>
                </div>
                <input
                  defaultValue={linkObj[link.name]}
                  name={link.name}
                  onChange={onChangeValueofLink}
                  placeholder={`Paste your ${link.placeholderName} profile link`}
                />
              </div>
            ))}

            {otherLinks?.map((link) => (
              <div key={link.id} className="linkDiv">
                <div className="linkIconAndImage">
                  {/* <img src={link.icon} alt={link.name} /> */}
                  <p className="linkIconName">{link.linkTitle}</p>
                </div>
                <div className="linksInputContainer">
                  <input
                    defaultValue={link?.linkUrl}
                    name={link.name}
                    onChange={onChangeValueofLink}
                    placeholder={`Paste your ${link.placeholderName} profile link`}
                  />
                  <div>
                    <span
                      className="material-symbols-outlined"
                      onClick={() =>
                        removeOptionLink(link?._id, link?.linkTitle)
                      }
                    >
                      delete
                    </span>
                  </div>
                </div>
              </div>
            ))}

            <div
              style={
                isAddNewLinkOpen ? { display: "none" } : { display: "flex" }
              }
              className="addNewLinkBtn"
              onClick={() => setIsAddNewLinkOpen(true)}
            >
              <span class="material-symbols-outlined">add_circle</span>
              Add Custom Link
            </div>
          </div>
        </div>
      </form>
      <div className="newLinkContainer">
        <div
          style={!isAddNewLinkOpen ? { display: "none" } : { display: "block" }}
          className="newLinkAddBox"
        >
          <div className="linkDiv otherNewLink">
            <div className="newLinkTitle">
              <p>Link Title</p>
              <input
                value={linkTitle}
                onChange={(e) => setLinkTitle(e.target.value)}
                placeholder="Give the link to a title"
              />
            </div>
            <div className="newLinkUrl">
              <p>Link</p>
              <input
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                placeholder="Enter the link here"
              />
            </div>
          </div>
          <div className="newFormButtons">
            <TwoButtonWrapper
              firstComponent={"Cancel"}
              backgroundColor={"white"}
              color={"black"}
              padding={"5px 20px"}
              height={"45px"}
              borderRadius={"10px"}
              componentFunction={() => setIsAddNewLinkOpen(false)}
            ></TwoButtonWrapper>
            <TwoButtonWrapper
              firstComponent={"Save"}
              padding={"5px 20px"}
              height={"45px"}
              borderRadius={"10px"}
              componentFunction={handleAddNewLink}
            ></TwoButtonWrapper>
          </div>
        </div>
      </div>

      <div className="linkFormDecidingButtons">
        <TwoButtonWrapper
          firstComponent={"Cancel"}
          backgroundColor={"white"}
          color={"black"}
          padding={"5px 20px"}
          height={"45px"}
          borderRadius={"10px"}
        ></TwoButtonWrapper>
        <TwoButtonWrapper
          firstComponent={"Save"}
          padding={"5px 20px"}
          height={"45px"}
          borderRadius={"10px"}
          componentFunction={updateUserLinks}
        ></TwoButtonWrapper>
      </div>

      {/* <div className="formButtons">
        <div className="innerFormContainer">
          <button className="formBtn">Cancel</button>
          <button onClick={updateUserLinks} type="submit" className="formBtn">
            {saveText}
          </button>
        </div>
      </div> */}
    </main>
  );
};

export default Links;
