import React, { useEffect, useRef, useState } from "react";
import "./YourSessions.css";
import SessionForm from "./SessionForm";
import SessionTemplateSelector from "./SessionTemplateSelector";
import { SlCalender } from "react-icons/sl";
import { FaArrowRight } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import axios from "../../../../../axios";
import NewLoader from "../../../../loader/NewLoader/NewLoader";
import { useTour } from "../../../../../context/TourProviderContext";

function YourSessions() {
  const [loader, setLoader] = useState(true);

  const [showForm, setShowForm] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [editDeleteDropdown, setEditDeleteDropdown] = useState({});
  const [currentSessionData, setCurrentSessionData] = useState(null);
  const [availabilityTaken, setAvailabilityTaken] = useState(false);
  const [editSelected, setEditSelected] = useState(false);
  const { startTour } = useTour();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/mentoring/get-all-mentor-sessions`, {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      });

      setSessions(data);
      setLoader(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/mentoring/get-general-availability/${Marketer.user._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      if (data?.length < 1) {
        setAvailabilityTaken(false);
      } else {
        setAvailabilityTaken(true);
      }
    })();
  });

  const handleCreateNewSessionClick = () => {
    if (!availabilityTaken) {
      startTour("fill-availability");
    } else {
      setShowForm(true);
    }
  };

  const handleFormSubmit = async (newSession) => {
    const { data } = await axios.post(
      "/mentoring/create-session/",
      {
        ...newSession,
        mentor_id: Marketer.user._id,
      },
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    setSessions([...sessions, data?.session]);
    setShowForm(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setCurrentSessionData(null);
    setEditDeleteDropdown({});
  };

  const handleSelectTemplate = (template) => {
    handleFormSubmit(template);
  };

  const handleEditSelectedTemplate = (template) => {
    setEditSelected(true);
    setCurrentSessionData(template);
    setShowForm(true);
  };

  const onEditSession = async (id, newSession) => {
    const { data } = await axios.post(
      `/mentoring/update-mentoring-session/${id}`,
      newSession,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    setShowForm(false);
    setEditDeleteDropdown((prevData) => ({ ...prevData, [id]: false }));
    setSessions((prevData) =>
      prevData.map((session) => (session._id === id ? data?.data : session))
    );
    setCurrentSessionData(null);
  };

  if (showForm) {
    return (
      <SessionForm
        editSelected={editSelected}
        onSubmit={handleFormSubmit}
        onClose={handleCloseForm}
        currentSessionData={currentSessionData}
        onEditSession={onEditSession}
      />
    );
  }

  const handleEditDelete = (id) => {
    setEditDeleteDropdown((prevData) => ({ ...prevData, [id]: !prevData[id] }));
  };

  const handleSessionEdit = async (id) => {
    setShowForm(true);
    const data = sessions.find((session) => session._id === id);
    setCurrentSessionData(data);
  };
  const handleSessionDelete = async (id) => {
    const { data } = await axios.delete(
      `/mentoring/delete-mentoring-session/${id}`,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    setSessions((prevData) => prevData.filter((session) => session._id !== id));
  };

  return (
    <>
      {loader ? (
        <NewLoader></NewLoader>
      ) : (
        <>
          <div className="your-sessions-container">
            {sessions.map((session, index) => (
              <div className="singleSessionBox" key={index}>
                <div className="session-item">
                  <div className="sessionTypeAndEdit">
                    <div className="session-type">{session.type}</div>
                    <div className="edit-session">
                      <IoMdMore
                        style={{
                          width: "25px",
                          height: "25px",
                        }}
                        onClick={() => handleEditDelete(session?._id)}
                      ></IoMdMore>
                    </div>
                    {editDeleteDropdown[session?._id] && (
                      <div className="editDeleteDropdown">
                        <button
                          className="session-edit-button"
                          onClick={() => handleSessionEdit(session?._id)}
                        >
                          Edit
                        </button>
                        <button
                          className="session-delete-button"
                          onClick={() => handleSessionDelete(session?._id)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="session-title">{session.title}</div>
                  <div className="session-description">
                    {session.description}
                  </div>
                  <div className="session-details">
                    <div className="session-duration">
                      <span className="material-symbols-outlined">
                        hourglass_bottom
                      </span>

                      <p>{session.duration}</p>
                      {" min"}
                    </div>
                    <div className="session-price">
                      <p>₹ {session.price}</p>

                      <span className="material-symbols-outlined">
                        arrow_right_alt
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div
              className="create-session-box"
              onClick={handleCreateNewSessionClick}
            >
              <button className="create-session-button">
                + Create New Session
              </button>
            </div>
          </div>
          <SessionTemplateSelector
            onSelectTemplate={handleSelectTemplate}
            handleEditSelectedTemplate={handleEditSelectedTemplate}
          />
        </>
      )}
    </>
  );
}

export default YourSessions;
