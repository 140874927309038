import React, { useState, useEffect, useCallback } from "react";
import "./SessionTemplateSelector.css";
import { FaArrowRight, FaEdit } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import NewLoader from "../../../../loader/NewLoader/NewLoader";
import axios from "../../../../../axios";
import { IoFunnelOutline } from "react-icons/io5";
import { Oval } from "react-loader-spinner";

const debounce = (cb, delay) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

function SessionTemplateSelector({
  onSelectTemplate,
  handleEditSelectedTemplate,
}) {
  const [sessionTemplateLoader, setSessionTemplateLoader] = useState(true);
  const [sessionTemplates, setSessionTemplates] = useState([]);
  const [selectedSessionTemplate, setSelectedSessionTemplate] = useState(null);
  const [sessionTemplateFilterQuery, setSessionTemplateFilterQuery] =
    useState("");
  const [filterList, setFilterList] = useState([]);
  const [searchFilterValues, setSearchFilterValues] = useState({
    position: "",
    sessionFocus: "",
    duration: "",
  });
  const [searchLoader, setSearchLoader] = useState({
    position: false,
    sessionFocus: false,
    duration: false,
  });
  const [filterSeachActivated, setFilterSearchActivated] = useState({});
  const [searchedFilterOptions, setSearchedFilterOptions] = useState([]);
  const [filterBackendData, setFilterBackendData] = useState({
    position: [],
    sessionFocus: [],
    duration: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const getSessionData = async (currentPage) => {
    try {
      setSessionTemplateLoader(true);
      const { data } = await axios.post(
        "/mentoring/get-all-session-template",
        {
          filters: filterBackendData,
          pageInfo: {
            pageLimit: itemsPerPage,
            pageNumber: currentPage || 1,
          },
        },
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      if (data?.success) {
        setSessionTemplates(data?.data); // Fallback to sample templates if API fails
        setSessionTemplateLoader(false);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setSessionTemplateLoader(false);
    }
  };

  useEffect(() => {
    // Fetch templates from the server
    if (currentPage)
      (async () => {
        await getSessionData(currentPage);
      })();
  }, [currentPage]);

  const handleSessionTemplateClick = (template) => {
    const { _id, position, ...templateData } = template;
    onSelectTemplate(templateData);
  };

  const handleEditSessionTemplate = (template, e) => {
    e.stopPropagation(); // Prevent triggering the template selection
    handleEditSelectedTemplate(template);
  };

  const addFilter = (filterData, type) => {
    setFilterList((prevFilters) => [...prevFilters, { filterData, type }]);
    setFilterSearchActivated({});
    setSearchedFilterOptions([]);
    setSearchFilterValues((prevValues) => ({
      ...prevValues,
      [type]: [...(prevValues[type] || []), filterData], // Append to existing array
    }));
    setSearchFilterValues({
      position: "",
      sessionFocus: "",
      duration: "",
    });
    setFilterBackendData((prevFilters) => ({
      ...prevFilters,
      [type]: [...(prevFilters[type] || []), filterData],
    }));
  };

  const applyFilters = async () => {
    setCurrentPage(1);
    await getSessionData(1);
  };

  const removeFilter = (filter_name) => {
    setFilterList((prevFilters) =>
      prevFilters.filter((item) => item.filterData !== filter_name)
    );
    setFilterBackendData((prevFilters) =>
      Object.keys(prevFilters).reduce((acc, key) => {
        acc[key] = prevFilters[key].filter((item) => item !== filter_name);
        return acc;
      }, {})
    );
  };

  const searchFilterDebounceFunction = useCallback(
    debounce(async (filter_value, type) => {
      if (filter_value === "") {
        console.log("after being empty");
        setFilterSearchActivated({});
        setSearchedFilterOptions([]);
        return;
      }

      setSearchLoader({ [type]: true });
      const { data } = await axios.post(
        "/mentoring/session-template/filter-search/",
        {
          field: type,
          value: filter_value,
        },
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      if (data?.success) {
        console.log("afer data sucess");
        setSearchedFilterOptions([...data?.data]);
        setFilterSearchActivated({ [type]: true });
        setSearchLoader({ [type]: false });
      }
    }, 500),
    []
  );

  const handleSearchFilter = async (filter_value, type) => {
    setSearchFilterValues({ ...searchFilterValues, [type]: filter_value });

    searchFilterDebounceFunction(filter_value, type);
  };

  return (
    <div className="session-template-selector-container">
      <div className="session-template-selector-header">
        <h2>Select a session template</h2>
        {/* <button className="session-template-close-button" onClick={onClose}>
          ×
        </button> */}
      </div>

      <div className="filter-section">
        <div className="filter-tab">
          <div>
            <button onClick={applyFilters}>Apply</button>
          </div>
          <div className="filter-tab-header">
            <IoFunnelOutline />
            <p>Filtered By</p>
          </div>

          <div className="filter-options-container">
            <div className="filter-dropdown">
              <div className="filter-input-container">
                <input
                  type="text"
                  placeholder="Search position"
                  className="filter-search-input"
                  value={searchFilterValues["position"]}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "position")
                  }
                />
                {searchLoader["position"] && (
                  <Oval
                    height="20"
                    width="20"
                    radius="9"
                    color="gray"
                    ariaLabel="loading"
                  />
                )}
              </div>
              {filterSeachActivated["position"] &&
                searchedFilterOptions.length > 0 && (
                  <div className="filter-options-list">
                    {searchedFilterOptions?.map((option) => (
                      <div
                        className="filter-option-item"
                        onClick={() => addFilter(option, "position")}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
            </div>

            <div className="filter-dropdown">
              <div className="filter-input-container">
                <input
                  type="text"
                  placeholder="Search sessionFocus"
                  className="filter-search-input"
                  value={searchFilterValues["sessionFocus"]}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "sessionFocus")
                  }
                />
                {searchLoader["sessionFocus"] && (
                  <Oval
                    height="20"
                    width="20"
                    radius="9"
                    color="gray"
                    ariaLabel="loading"
                  />
                )}
              </div>
              {filterSeachActivated["sessionFocus"] &&
                searchedFilterOptions.length > 0 && (
                  <div className="filter-options-list">
                    {searchedFilterOptions?.map((option) => (
                      <div
                        className="filter-option-item"
                        onClick={() => addFilter(option, "sessionFocus")}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
            </div>

            <div className="filter-dropdown">
              <div className="filter-input-container">
                <input
                  type="text"
                  placeholder="Search duration"
                  className="filter-search-input"
                  value={searchFilterValues["duration"]}
                  onChange={(e) =>
                    handleSearchFilter(e.target.value, "duration")
                  }
                />
                {searchLoader["duration"] && (
                  <Oval
                    height="20"
                    width="20"
                    radius="9"
                    color="gray"
                    ariaLabel="loading"
                  />
                )}
              </div>
              {filterSeachActivated["duration"] &&
                searchedFilterOptions.length > 0 && (
                  <div className="filter-options-list">
                    {searchedFilterOptions?.map((option) => (
                      <div
                        className="filter-option-item"
                        onClick={() => addFilter(option, "duration")}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>

          {filterList?.length > 0 && (
            <>
              <div className="filter-selected-container">
                {filterList?.map((filter) => (
                  <div className={`filter-selected-item ${filter?.type}`}>
                    {filter?.filterData}
                    <RxCross2
                      className="filter-remove-icon"
                      onClick={() => removeFilter(filter?.filterData)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {sessionTemplateLoader ? (
        <NewLoader />
      ) : (
        <>
          <div className="session-template-grid">
            {sessionTemplates.map((template) => (
              <div
                key={template._id}
                className={`session-template-item ${
                  selectedSessionTemplate?._id === template._id
                    ? "session-template-selected"
                    : ""
                }`}
                onClick={() => handleSessionTemplateClick(template)}
              >
                <div className="session-template-type-row">
                  <div className="session-template-type">{template.type}</div>
                  <button
                    className="session-template-edit-button"
                    onClick={(e) => handleEditSessionTemplate(template, e)}
                  >
                    <FaEdit />
                  </button>
                </div>
                <div className="session-template-title">{template.title}</div>
                <div className="session-template-description">
                  {template.description}
                </div>
                <div className="session-template-details">
                  <div className="session-template-duration">
                    <span className="material-symbols-outlined">
                      hourglass_bottom
                    </span>
                    <p>{template.duration}</p>
                    {" min"}
                  </div>
                  <div className="session-template-price">
                    <p>₹ {template.price}</p>
                    <span className="material-symbols-outlined">
                      arrow_right_alt
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="session-template-pagination-container">
            <button
              className="session-template-pagination-button"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="session-template-pagination-page-number">
              {currentPage}
            </span>
            <button
              className="session-template-pagination-button"
              onClick={() => setCurrentPage((prev) => prev + 1)}
              disabled={sessionTemplates.length < itemsPerPage}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default SessionTemplateSelector;
