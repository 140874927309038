import React, { useEffect } from "react";
import { useState } from "react";
import "./pannel.css";
import copyClipboard from "../img/copyClipboard.png";
import { useLocation } from "react-router-dom";
import Credits from "../Credits/Credits";
import Setting from "../Setting/Setting";
import CreateProfile from "../CompanyProfile/CompanyProfile";
import BreadCrumb from "../../BreadCrumb/BreadCrumb";
import Talent from "../Talent/Talent";
import Profile from "../Profile/Profile";
import BankDetails from "../BankDetails/BankDetails";
import PoolAnalysis from "../Talent/PoolAnalysis/PoolAnalysis";
import LinkedInUrl from "../Profile/LinkedInUrl/LinkedInUrl";
import Mentoring from "../Mentoring/Mentoring";
import Activity from "../Activity/Activity";
import { UserMessageAndNudge } from "../UserMessageAndNudge/UserMessageAndNudge";
import { useTour } from "../../../context/TourProviderContext";

const Pannel = ({
  query,
  setQuery,
  Fields,
  caseStudies,
  onsubmitForm,
  userData,
  setUserData,
  setCurrentProfileData,
  currentProfileData,
  setMarketersdata,
  rejectCasestudy,
  updatePassword,
  selectedCompany,
  setSelectedCompany,
  breadCrumbNextRoute,
  setBreadCrumbNextRoute,
  socialLinks,
}) => {
  const [copyProfileLink, setProfieLink] = useState(false);
  // const [route, setRoute] = useState(window.location.pathname.split("/"));
  const location = useLocation();
  const [copyText, setCopyText] = useState("Copy profile link");
  const [openLinkedInUrl, setOpenLinkedInUrl] = useState(false);
  const { startTour } = useTour();

  useEffect(() => {
    {
      Fields.map((key) => {
        if (key.name === location.pathname.split("/")[2]) {
          setBreadCrumbNextRoute(key.text);
        }
      });
    }
  }, [location]);

  const handlePannel = () => {
    if (location.pathname === "/user-dashboard") {
      return (
        <Profile
          query={query}
          setQuery={setQuery}
          userData={userData}
          setUserData={setUserData}
          currentProfileData={currentProfileData}
          setCurrentProfileData={setCurrentProfileData}
          caseStudies={caseStudies}
          onsubmitForm={onsubmitForm}
          socialLinks={socialLinks}
          setMarketersdata={setMarketersdata}
        />
      );
    } else if (location.pathname === "/user-dashboard/credits") {
      return (
        <Credits caseStudies={caseStudies} rejectCasestudy={rejectCasestudy} />
      );
    } else if (location.pathname === "/user-dashboard/talent") {
      return <Talent />;
    } else if (location.pathname === "/user-dashboard/profile") {
      return (
        <Profile
          query={query}
          setQuery={setQuery}
          userData={userData}
          setUserData={setUserData}
          currentProfileData={currentProfileData}
          setCurrentProfileData={setCurrentProfileData}
          caseStudies={caseStudies}
          onsubmitForm={onsubmitForm}
          socialLinks={socialLinks}
          setMarketersdata={setMarketersdata}
        />
      );
    } else if (location.pathname === "/user-dashboard/setting") {
      return <Setting updatePassword={updatePassword} />;
    } else if (location.pathname === "/user-dashboard/new-company-profiles") {
      return (
        <CreateProfile
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      );
    } else if (location.pathname === "/user-dashboard/message") {
      return <UserMessageAndNudge />;
    } else if (location.pathname === "/user-dashboard/bank-details") {
      return <BankDetails />;
    } else if (location.pathname === "/user-dashboard/pool-analytics") {
      return <PoolAnalysis />;
    } else if (location.pathname === "/user-dashboard/mentoring") {
      return <Mentoring />;
    } else if (location.pathname === "/user-dashboard/activity") {
      return <Activity />;
    }
  };

  useEffect(() => {
    handlePannel();
  }, [location.search]);

  function handleCopy(userData) {
    console.log(copyProfileLink);
    navigator.clipboard.writeText(
      `https://unispade.com/userprofile/${userData?.username}`
    );
    setProfieLink(true);
  }

  useEffect(() => {
    if (copyProfileLink) {
      setCopyText("Copied");
    }
  }, [copyProfileLink]);

  useEffect(() => {
    if (copyText === "Copied") {
      setTimeout(() => {
        setCopyText("Copy profile link");
        setProfieLink(false);
      }, 3000);
    }
  }, [copyText]);

  return (
    <div className="Pannel">
      <div className="profile">
        <BreadCrumb
          breadCrumbNextRoute={breadCrumbNextRoute}
          setBreadCrumbNextRoute={setBreadCrumbNextRoute}
          setSelectedCompany={setSelectedCompany}
        />
        <div className="extraButtonsContainers">
          <button onClick={() => startTour(1)}> Take a tour </button>
          <div onClick={() => handleCopy(userData)} className="copyProfileLink">
            <img src={copyClipboard} alt="" />
            <p>{copyText}</p>
          </div>
        </div>
      </div>

      {openLinkedInUrl && (
        <LinkedInUrl
          setOpenLinkedInUrl={setOpenLinkedInUrl}
          setMarketersdata={setMarketersdata}
        ></LinkedInUrl>
      )}

      {handlePannel()}
    </div>
  );
};

export default Pannel;
