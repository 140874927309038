import React, { useState } from "react";
import "./SessionForm.css";
import axios from "../../../../../axios";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import DropdownMenuList from "../../../../../atoms/DropdownList/DropdownMenuList";

const ExpertiseIndustryData = {
  "Product Management": [
    "FinTech",
    "eCommerce & Quick Commerce",
    "AI",
    "Healthcare",
    "Entertainment & Streaming",
    "Logistics & Supply Chain",
    "Industrial Agnostic",
  ],
  Marketing: [
    "FinTech",
    "eCommerce & Quick Commerce",
    "AI",
    "Healthcare",
    "Entertainment & Streaming",
    "Logistics & Supply Chain",
    "Industrial Agnostic",
  ],
};

const SessionForm = ({
  editSelected,
  onSubmit,
  onClose,
  currentSessionData,
  onEditSession,
}) => {
  const [type, setType] = useState("Video Call");
  const [title, setSessionTitle] = useState(currentSessionData?.title || "");
  const [duration, setDuration] = useState(currentSessionData?.duration || "");
  const [price, setPrice] = useState(currentSessionData?.price || "");
  const [description, setDescription] = useState(
    currentSessionData?.description || ""
  );
  const [sessionFocus, setSessionFocus] = useState(
    currentSessionData?.sessionFocus || ""
  );
  const [sessionIndustry, setSessionIndustry] = useState(
    currentSessionData?.sessionIndustry || ""
  );

  const [dropdown, setDropdown] = useState({});

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSession = {
      type,
      title,
      duration,
      price,
      description,
      sessionFocus,
      sessionIndustry,
    };
    if (editSelected || !currentSessionData) {
      onSubmit(newSession);
    } else if (currentSessionData) {
      onEditSession(currentSessionData._id, newSession);
    }
    setType("Video Call");
    setSessionTitle("");
    setDuration("");
    setPrice("");
    setDescription("");
    setSessionFocus("");
    setSessionIndustry("");
  };

  const disabledTypes = [
    "Webinar",
    "Email",
    "Long-term Mentoring",
    "Package",
    "Subscription",
  ];

  const handleTypeChange = (sessionType) => {
    setType(sessionType);
  };

  const handleDropDown = (name) => {
    setDropdown({ [name]: !dropdown[name] });
  };

  return (
    <div className="session-form-overlay">
      <div className="session-form-container">
        <form className="session-form" onSubmit={handleSubmit}>
          <div className="session-form-tabs">
            <div
              className={`session-type-button ${
                type === "Video Call" ? "active" : ""
              }`}
              onClick={() => handleTypeChange("Video Call")}
            >
              Video Call
            </div>
            {disabledTypes.map((sessionType) => (
              <div
                key={sessionType}
                className={`session-type-button ${
                  type === sessionType ? "active" : ""
                } disabled`}
              >
                {sessionType}
              </div>
            ))}
          </div>
          <div className="session-form-content">
            <div className="session-form-field">
              <label>Session Title:</label>
              <div className="mentorInputContainer">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setSessionTitle(e.target.value)}
                  required
                  placeholder="  Session title"
                />
              </div>
            </div>
            <div className="session-form-field">
              <label>Session duration:</label>
              <div className="mentorInputContainer">
                <input
                  type="text"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  required
                  placeholder="Session duration"
                />
                <p className="inputSubtext">in minutes</p>
              </div>
            </div>
            <div className="session-form-field">
              <label>Session price:</label>
              <div className="mentorInputContainer">
                <input
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                  placeholder="Session price"
                />
                <p className="inputSubtext">
                  in INR{" ("}
                  <span class="material-symbols-outlined">currency_rupee</span>
                  {")"}
                </p>
              </div>
            </div>

            <div className="session-form-field">
              <label>Session Focus:</label>
              <div className="mentorInputContainer">
                <input
                  type="text"
                  value={sessionFocus}
                  readOnly
                  required
                  placeholder="Select your session focus"
                />
                <div onClick={() => handleDropDown("sessionFocus")}>
                  {!dropdown["sessionFocus"] ? (
                    <MdExpandMore></MdExpandMore>
                  ) : (
                    <MdExpandLess></MdExpandLess>
                  )}
                </div>

                {dropdown["sessionFocus"] && (
                  <DropdownMenuList
                    options={Object.keys(ExpertiseIndustryData)}
                    handleSelection={setSessionFocus}
                    onClose={() => handleDropDown("sessionFocus")}
                  />
                )}
              </div>
            </div>

            <div className="session-form-field">
              <label>Session Industry:</label>
              <div
                className="mentorInputContainer"
                style={{
                  cursor: sessionFocus === "" ? "not-allowed" : "pointer",
                }}
              >
                <input
                  type="text"
                  value={sessionIndustry}
                  readOnly
                  required
                  placeholder="Select your session industry"
                />
                <div onClick={() => handleDropDown("sessionIndustry")}>
                  {!dropdown["sessionIndustry"] ? (
                    <MdExpandMore></MdExpandMore>
                  ) : (
                    <MdExpandLess></MdExpandLess>
                  )}
                </div>

                {dropdown["sessionIndustry"] && (
                  <DropdownMenuList
                    options={ExpertiseIndustryData[sessionFocus]}
                    handleSelection={setSessionIndustry}
                    onClose={() => handleDropDown("sessionIndustry")}
                  />
                )}
              </div>
            </div>

            <div className="session-form-field">
              <label>Session description:</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                style={{ height: "250px" }}
                placeholder="  Session description"
              ></textarea>
            </div>
            <div className="session-form-buttons">
              <button
                type="button"
                onClick={onClose}
                className="cancel-button1"
              >
                Cancel
              </button>
              <button type="submit" className="save-button">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SessionForm;
