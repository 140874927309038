import React from "react";
import Joyride from "react-joyride";

export const TourElement = ({ steps, isRun, handleJoyrideCallback }) => {
  return (
    <div className="TourElementComponent">
      <Joyride
        steps={steps}
        continuous={true}
        run={isRun}
        disableOverlayClose={true}
        callback={handleJoyrideCallback}
        disableBeacon={true}
        styles={{
          options: {
            zIndex: 1000,
            primaryColor: "#000", // Next button color
          },
          tooltip: {
            borderRadius: "10px",
            boxShadow: "0px 10px 10px rgba(0,0,0,0.2)", // Shadow effect
            padding: "15px",
            fontSize: "16px",
            fontWeight: "700",
          },
          buttonBack: {
            color: "#fff",
            backgroundColor: "#000", // Back button color
            borderRadius: "10px",
            padding: "8px 12px",
            fontWeight: "800",
          },
          buttonNext: {
            color: "#fff",
            backgroundColor: "#000", // Next button color
            borderRadius: "10px",
            padding: "8px 12px",
            fontWeight: "800",
          },
        }}
      />
    </div>
  );
};
