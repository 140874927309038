import React from "react";
import SapFilters from "./SapFilters";
import "../../styles/Sap.css";
import SapSidebar from "./SapSidebar";

import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import SapAgencies from "./SapAgencies";
import SapLeads from "./SapLeads";
import SapService from "./SapService";
import SapContent from "./SapContent";
import SapServiceAddEdit from "./SapServiceAddEdit";
import { SapSessionTemplate } from "./SessionTemplate/SapSessionTemplate";
import { SapDiscountCoupon } from "./SapDiscountCoupon/SapDiscountCoupon";

function Sapmain() {
  const history = useHistory();
  if (localStorage.getItem("sapuser") == null) {
    history.push("/saplogin");
  }
  return (
    <div className="sap">
      <ScrollToTop />
      <SapSidebar />
      <Switch>
        <Route path="/sap/service" exact component={SapService} />
        <Route
          path="/sap/service/addedit"
          exact
          component={SapServiceAddEdit}
        />
        <Route path="/sap/leads" exact component={SapLeads} />
        <Route path="/sap/filters" exact component={SapFilters} />
        <Route path="/sap/agencies" exact component={SapAgencies} />
        <Route path="/sap/content" exact component={SapContent} />
        <Route
          path="/sap/session-templates"
          exact
          component={SapSessionTemplate}
        />
        <Route path="/sap/coupon" exact component={SapDiscountCoupon} />
      </Switch>
    </div>
  );
}

export default Sapmain;
