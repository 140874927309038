import React, { useState } from "react";
import "./PaymentPage.css";
import arrowIcon from "../img/arrow_back_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import calendar from "../img/calendar_month_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import time from "../img/schedule_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import PaymentDone from "./paymentDone"; // Assuming paymentDone.jsx is in the same directory
import axios from "../../../../../axios.js";
import mobileArrow from "../img/chevron_left_40dp_FILL0_wght400_GRAD0_opsz40.svg";
import { toast } from "react-toastify";
import toastStyle from "../../../../../configs/toastConfig.js";

function PaymentPage({
  selectedDate,
  selectedTimeSlot,
  requestBody,
  setRequestBody,
  sessionDetails,
  setShowPaymentPage,
}) {
  const [redirect, setRedirect] = useState(false);
  const [agenda, setAgenda] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [selectedCouponData, setSelectedCouponData] = useState(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const verifyCoupon = async () => {
    try {
      const { data } = await axios.post(
        "/mentoring/verify-coupon",
        {
          originalPrice: sessionDetails?.price,
          couponCode: couponCode,
          userId: Marketer.user._id,
          mentorId: sessionDetails?.mentor_id?._id,
        },
        {
          headers: {
            Authorization: "Bearer " + Marketer.token,
          },
        }
      );

      if (data?.success) {
        toast.success(data?.message, toastStyle);
        setDiscountedPrice(data?.data?.discountedPrice);
        setSelectedCouponData(data?.data);
      } else {
        toast.error(data?.message, toastStyle);
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data?.message || "Something went wrong", toastStyle);
      } else {
        toast.error("Network error or server is unreachable", toastStyle);
      }
    }
  };

  const handlePayment = async () => {
    // Redirect logic here

    const theRequestObject = { ...requestBody, agenda_insights: agenda };
    const { data } = await axios.post(
      "/mentoring/set-appointment",
      theRequestObject,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    const gstAmount = ((discountedPrice || sessionDetails?.price) * 18) / 100;

    const payload = {
      appointment_id: data?._id,
      session_id: data?.session_id,
      user_id: data?.user_id,
      mentor_id: data?.mentor_id,
      amount: discountedPrice || sessionDetails?.price,
      gst: gstAmount,
      totalAmount: (discountedPrice || sessionDetails?.price) + gstAmount,
    };

    console.log("the razor pay payload is ", payload);

    try {
      const response = await axios
        .post("/mentoring/create-mentoring-order", payload, {
          headers: { authorization: "Bearer " + Marketer?.token },
        })
        .then((res) => {
          if (res?.status === 200) {
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY_ID,
              amount: res?.data?.totalAmount,
              currency: "INR",
              name: "Unispade",
              description: "RazorPay",
              order_id: res?.data?.razorPayOrderId,
              async handler(response) {
                const verifydata = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                };
                try {
                  console.log("Verify payment ke just uppar");
                  const verifyResponse = await axios.post(
                    `/mentoring/verify-mentoring-payment/${data?._id}`,
                    verifydata,
                    {
                      headers: { authorization: "Bearer " + Marketer?.token },
                    }
                  );

                  if (verifyResponse?.status === 200) {
                    const { data } = await axios.post(
                      "/mentoring/apply-coupon",
                      {
                        couponId: selectedCouponData?._id,
                        userId: Marketer.user._id,
                        mentorId: sessionDetails?.mentor_id?._id,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + Marketer.token,
                        },
                      }
                    );

                    if (!data?.success) {
                      throw new Error(data?.message);
                    }
                    setRedirect(true);
                  } else {
                    await axios.delete(
                      `/mentoring/delete-appointment/${data?._id}`,
                      {
                        headers: {
                          authorization: "Bearer " + Marketer.token,
                        },
                      }
                    );
                    // history.push("/payment-failed");
                  }
                } catch (verifyError) {
                  console.error("Error while verifying payment:", verifyError);
                }
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#121212",
              },
              modal: {
                ondismiss: async function () {
                  console.log("Payment popup closed");
                  await axios.delete(
                    `/mentoring/delete-appointment/${data?._id}`,
                    {
                      headers: {
                        authorization: "Bearer " + Marketer.token,
                      },
                    }
                  );
                },
              },
            };
            const razor = new window.Razorpay(options);
            razor.open();
          } else {
            console.log("payment ke if mei aa chuke hai ham bayankar");
          }
        });
    } catch (error) {
      console.error("Error while creating payment:", error);

      await axios.delete(`/mentoring/delete-appointment/${data?._id}`, {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      });
    }
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-IN", options);
  };

  const createAgenda = (event) => {
    const newAgenda = event.target.value;
    setAgenda(newAgenda);
  };

  // Redirect to paymentDone.jsx if redirect state is true
  if (redirect) {
    return (
      <PaymentDone
        selectedDate={selectedDate}
        selectedTimeSlot={selectedTimeSlot}
        sessionDetails={sessionDetails}
      />
    );
  }

  return (
    <div className="userPaymentSingleSession">
      <div className="sessionHeader">
        <div className="sessionHeader-title">
          <button
            className="backButton"
            onClick={() => setShowPaymentPage(false)}
          >
            <img src={arrowIcon} alt="left arrow" />
          </button>
          <h1 className="sessionHeader-title heading">
            {sessionDetails?.title}
          </h1>
          <div className="mobile-icon">
            <img src={mobileArrow} alt="mobile arrow" />
          </div>
        </div>
        <div className="payment-page-duration-slot">
          <div className="payment-page-duration-item">
            <span>
              <img src={calendar} alt="calendar" /> {formatDate(selectedDate)}
            </span>
          </div>
          <div className="payment-page-duration-item">
            <span>
              <img src={time} alt="time" /> {selectedTimeSlot}
            </span>
          </div>
        </div>
      </div>
      <div className="payment-section">
        <div className="duration-footer1">
          <div className="duration-item-footer1">
            <span>
              <img src={calendar} alt="calendar" />{" "}
              {selectedDate.toLocaleDateString()}
            </span>
          </div>
          <div className="duration-item-footer1">
            <span>
              <img src={time} alt="time" /> {selectedTimeSlot}
            </span>
          </div>
        </div>
        <div className="payment-section-title">
          <p>Agenda and Insights</p>
          <textarea
            placeholder="In a few lines, describe: 
• something about you 
• your expectations from the call 
• details that can help the mentor guide you better"
            onChange={createAgenda}
            className="agenda-input"
            onFocus={(e) => {
              if (e.target.value === e.target.defaultValue) {
                e.target.value = "";
                e.target.style.color = "#000";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.value = e.target.defaultValue;
                e.target.style.color = "#999999";
              }
            }}
          />
        </div>
      </div>

      <div className="payment-section-footer">
        <div className="payment-section-footer-price">
          <span
            className="session-original-price"
            style={{
              textDecoration: discountedPrice ? "line-through" : "none",
            }}
          >
            {" "}
            &#8377; {sessionDetails?.price}
          </span>
          {discountedPrice && (
            <span className="session-discounted-price">
              {selectedCouponData?.discountInPercentage ? (
                <span>
                  {selectedCouponData?.discountInPercentage}% off{" - "}
                  {selectedCouponData?.couponCode}
                </span>
              ) : (
                selectedCouponData?.discountInAmount && (
                  <span>
                    &#8377; {selectedCouponData?.discountInAmount} off{" - "}
                    {selectedCouponData?.couponCode}
                  </span>
                )
              )}
              &#8377; {discountedPrice}
            </span>
          )}
        </div>
        <div className="payment-section-coupon-and-payment">
          <div className="payment-section-coupon">
            <div className="coupon-input-container">
              <input
                type="text"
                placeholder="Enter Coupon Code"
                className="coupon-input"
                onChange={(e) => setCouponCode(e.target.value)}
                value={couponCode}
              />
              <button className="coupon-button" onClick={verifyCoupon}>
                Apply
              </button>
            </div>
          </div>

          <button
            className="payment-section-footer-payment"
            onClick={handlePayment}
          >
            Make Payment
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentPage;
