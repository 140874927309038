import { createContext, useContext, useState } from "react";
import Joyride from "react-joyride";
import { TourElement } from "../atoms/TourElement/TourElement";
const TourContext = createContext();

const stepsObject = {
  1: [
    {
      target: "[data-tour='NavbarProfileSectonTour']",
      content: "Fill up these all these details to be a valid user!",
      disableBeacon: true,
    },
    {
      target: "[data-tour='BasicInfoTabTour']",
      content:
        "For Mentors, only this section is required. Try 'Sync LinkedIn' feature.",
      disableBeacon: true,
    },
  ],
  2: [
    {
      target: "body", // No specific element, applies to the entire screen
      content:
        "Congratulations! You are eligible for mentoring! Please follow this guide to know what we offer!",
      placement: "center", // Places it in the middle of the screen
      disableBeacon: true,
    },
    {
      target: "[data-tour='MentoringSideBarTabTour']", // No specific element, applies to the entire screen
      content:
        "Create your mentoring sessions here, fill up the Availability first.",
      disableBeacon: true,
    },
    {
      target: "[data-tour='TalentSideBarTabTour']", // No specific element, applies to the entire screen
      content: "Add candidates to your pool, get some financial rewards.",
      disableBeacon: true,
    },
  ],
  "fill-availability": [
    {
      target: ".availability-icon",
      content: "Please enter your availability to setup any session",
      disableBeacon: true,
      spotlightClicks: true,
      showButtons: false, // This hides the Next/Back buttons
      showProgress: false,
      disableOverlayClose: false, // Allows closing when clicking on overlay
    },
  ],
};

export const TourProviderContext = ({ children }) => {
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);

  // Define tour steps across all components

  const startTour = (index) => {
    setSteps(stepsObject[index]);
    setRun(true);
  };

  const handleJoyrideCallback = (data) => {
    if (data.status === "finished" || data.status === "skipped") {
      setRun(false);
    }
  };

  return (
    <TourContext.Provider value={{ startTour }}>
      {children}
      <TourElement
        steps={steps}
        isRun={run}
        handleJoyrideCallback={handleJoyrideCallback}
      />
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
