import React, { useState } from "react";
import "../../styles/marketersLogin.css";
import axios from "../../axios.js";
import { useHistory, Link } from "react-router-dom";
import { getProfileRedirect, setProfileRedirect } from "../../common/service";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import toastStyle from "../../configs/toastConfig.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MarketersLogin(props) {
  const history = useHistory();

  const [submitbtn, setSubmitbtn] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [newMarketer, setNewMarketer] = useState({
    email: "",
    password: "",
  });

  const changeHandler = (event) => {
    setIsEmailValid(true);
    setIsPasswordValid(true);
    const { name, value } = event.target;
    setNewMarketer((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (props.isGoogleLogin) {
      loginThroughGoogle();
    }
  }, []);

  const loginThroughGoogle = async () => {
    setSubmitbtn(true);

    try {
      const response = await axios.get("/auth/login/success", {
        withCredentials: true,
      });
      processLoginResponse(response);
    } catch (err) {
      console.log(err);
    }

    setSubmitbtn(false);
  };

  const processLoginResponse = (response) => {
    if (response.data.message !== undefined) {
      setIsEmailValid(false);
      if (response.data.message === "Incorrect email or password")
        setIsPasswordValid(false);
    }
    if (response.data.token) {
      console.log(response.data);
      localStorage.setItem("marketer", JSON.stringify(response.data));
      const profileRedirect = getProfileRedirect();
      // Add condition for checking for chekcing the flag and redirect to the same link
      try {
        if (profileRedirect !== null) {
          history.push(profileRedirect);
        } else {
          history.push("/user-dashboard/profile");
        }
        props.toggleRefresh();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const submitHandler = async (e) => {
    // loading icon starts
    setSubmitbtn(true);
    // loading icon ends

    e.preventDefault();
    // const loginMarketer = newMarketer;
    var loginMarketer = {
      businessEmail: newMarketer.email,
      password: newMarketer.password,
      key: "marketer",
    };

    try {
      const response = await axios.post("/auth/login", loginMarketer);
      if (response.data.token) {
        toast.success("Login successful!", toastStyle); // Add this line
      }
      processLoginResponse(response);
    } catch (err) {
      toast.error("Login failed. Please check your credentials.", toastStyle);
      console.log(err);
    }

    // loading icon starts
    setSubmitbtn(false);
    // loading icon ends
  };

  const handleLoginSuccess = async (response) => {
    const { data } = await axios.post("/marketers/register-using-google", {
      token: response.credential,
    });

    if (data?.success) {
      localStorage.setItem("marketer", JSON.stringify(data?.data));
      toast.success("Login successful!", toastStyle);
      history.push("/user-dashboard/profile");
      props.toggleRefresh();
    } else {
      toast.error(data?.message, toastStyle);
    }
  };

  const handleLoginError = () => {
    toast.error("Google login failed. Please try again.", toastStyle);
    console.log("Login Error: Something went wrong during Google login.");
    // Handle any error that occurs during login
  };

  return (
    <div className="Marketerslogin__cont">
      <Helmet>
        <link rel="canonical" href="https://www.unispade.com/user-login" />
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="login">
        <h1>Welcome back!</h1>
        <div className="uLoginForm">
          <form className="login__form" onSubmit={submitHandler}>
            <label>Email ID*</label>
            <input
              style={{ backgroundColor: !isEmailValid ? "#F9B0B0" : "" }}
              onChange={changeHandler}
              name="email"
              type="email"
              value={newMarketer.email}
              placeholder="Enter your work email id"
              className="epass"
              required
            />
            <label>Password</label>
            <input
              style={{ backgroundColor: !isPasswordValid && "#F9B0B0" }}
              onChange={changeHandler}
              name="password"
              value={newMarketer.password}
              type="password"
              placeholder="Enter your password"
              className="epass"
              required
            />
            {(!isEmailValid || !isPasswordValid) && (
              <p>Incorrect Username or Password</p>
            )}
            <div className="log__for">
              <label>
                <input type="checkbox" />
                Keep me logged in
              </label>
              <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
                <label className="color-grey">Forgot Password?</label>
              </Link>{" "}
            </div>
            <button type="submit" className="login__btn">
              {submitbtn && (
                <i className="fas fa-circle-notch donebtnclient"></i>
              )}
              {submitbtn && " Logging In"}
              {!submitbtn && "Login"}
            </button>
            <div className="or-divider">
              <span className="or-line"></span>
              <span className="or-text">or</span>
              <span className="or-line"></span>
            </div>
            <div className="google-auth-container">
              <div className="google-auth-container">
                <div className="google-btn-wrapper">
                  <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={handleLoginError}
                    useOneTap
                    width="100%"
                    size="large"
                  />
                </div>
              </div>
            </div>

            <div className="account_status">
              <p>
                Don't Have an Account ?{" "}
                <span className="color-grey">
                  <b
                    onClick={() => {
                      window.location.href = "/user-registration";
                    }}
                  >
                    Register
                  </b>
                </span>{" "}
              </p>
              {/* <button type="button" class="login-with-google-btn" onClick={loginWithGoogle}>
                Sign in with Google
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MarketersLogin;
