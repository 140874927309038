import { React } from "react";
import "./InputField.css";
import moment from "moment";

export const InputField = ({
  label_name,
  name,
  type,
  formik,
  placeholder,
  placeholderInfo,
  onChange,
  readOnly,
}) => {
  return (
    <div className="input-field-container">
      {label_name && <label className="input-field-label">{label_name}</label>}
      {type === "checkbox" ? (
        <div className="checkbox-field-wrapper">
          <input
            type={type}
            name={name}
            checked={formik.values[name]}
            placeholder={placeholder}
            onChange={formik.handleChange}
            className="checkbox-field-input"
            readOnly={readOnly}
          />
        </div>
      ) : (
        <div className="input-field-wrapper">
          <input
            type={type}
            name={name}
            value={
              type === "date"
                ? moment(formik.values[name]).format("YYYY-MM-DD")
                : formik.values[name]
            }
            placeholder={placeholder}
            onChange={formik.handleChange}
            className="input-field-input"
            readOnly={readOnly}
          />
          {placeholderInfo && (
            <div className="sub-placeholder-info">{placeholderInfo}</div>
          )}
        </div>
      )}

      {formik.touched[name] && formik.errors[name] ? (
        <div className="form-error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};
