import React, { useEffect, useState } from "react";
import { MdOutlineDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { CiChat1 } from "react-icons/ci";
import { MdOutlineThumbsUpDown } from "react-icons/md";
import "./RequestingAdmission.css";
import LinkedInLogo from "../demoImages/linkedin.png";

import { FaInfo } from "react-icons/fa6";
import TwoButtonWrapper from "../../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import axios from "../../../../../axios";
import Demography from "../../../img/demography.png";
import { DropdownContentArea } from "../../../../../atoms/DropdownContentArea";
import ToggleSwitch from "../../../../../atoms/ToggleSwitch/ToggleSwitch";
import magnify from "../demoImages/magnify.svg";
import { ContactSupportOutlined } from "@material-ui/icons";
import { ChatBoxComponent } from "../../../../../atoms/ChatBoxComponent/ChatBoxComponent";
import { toast } from "react-toastify";
import toastStyle from "../../../../../configs/toastConfig";

export const RequestingAdmission = ({ selectedCategory }) => {
  const [userPrice, setUserPrice] = useState(selectedCategory?.price_per_user);
  const [isTopCandidate, setIsTopCandidate] = useState(false);

  const [skillSearchValue, setSkillSearchValue] = useState("");
  const [tagSeachValue, setTagSearchValue] = useState("");
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [rejectionComment, setrejectionComment] = useState("");

  const [admissionRequests, setAdmissionRequests] = useState([]);
  const [dropdownActivate, setDropdownActivate] = useState({});
  const [chatButton, setChatButton] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  console.log("The selected category is ", selectedCategory);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/talentPool/get-all-admission-requests/${selectedCategory?._id}`,
        {
          headers: {
            authorization: `Bearer ${Marketer.token}`,
          },
        }
      );

      console.log("The data is ", data?.data);

      setAdmissionRequests(data?.data);
    })();
  }, []);

  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  const handleChatButton = (id, chatBoxId) => {
    if (!chatButton[id]) {
      setDropdownActivate({ [id]: false });
      setChatButton({ [id]: true });
      getAllMessage(chatBoxId);
    } else {
      setChatButton({ [id]: false });
    }
  };
  const handleDropdown = (id) => {
    if (dropdownActivate[id]) {
      handleCancelButton(id);
    } else {
      setChatButton({ [id]: false });
      setDropdownActivate((prevData) => {
        return { [id]: true };
      });
    }
  };

  const getAllMessage = async (id) => {
    const { data } = await axios.get(
      `/talentPool/chat/get-admission-messages/${id}`,
      {
        headers: { authorization: "Bearer " + Marketer.token },
      }
    );

    setChatMessages(data?.data);
  };

  const handleSkillsSearch = async (e) => {
    setSkillSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_skills/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      setSuggestedSkills(data?.data);
    } else {
      let skills = suggestedSkills.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedSkills(skills);
    }
  };

  const handleTagsSearch = async (e) => {
    setTagSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_tags/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      setSuggestedTags(data?.data);
    } else {
      let tags = suggestedTags.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedTags(tags);
    }
  };

  async function addNewTag(e) {
    if (e.key === "Enter") {
      let { data } = await axios.post(
        `/talentPool/add_tag`,
        { name: e.target.value },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      setSelectedTags((prevData) => [...prevData, data?.data]);

      setTagSearchValue("");
    }
  }
  const selectSuggestedTag = (tag) => {
    setSelectedTags((prevData) => [...prevData, tag]);
    setTagSearchValue("");
  };

  const selectSuggestedSkill = (skill) => {
    setSelectedSkills((prevData) => [...prevData, skill]);
    setSkillSearchValue("");
  };
  const removeExistingTag = (tag) => {
    console.log("the removed tag is ", tag);
    console.log("The selected tags are ", selectedTags);
    const newSelectedTags = selectedTags?.filter((e) => {
      return e._id !== tag._id;
    });

    console.log("The new selected tags are ", newSelectedTags);
    setSelectedTags(newSelectedTags);
  };

  const removeExistingSkill = (skill) => {
    const newSelectedSkills = selectedSkills?.filter((e) => {
      return e._id !== skill._id; // Add 'return' statement
    });
    setSelectedSkills(newSelectedSkills);
  };

  const handleSaveButton = async (id, userData) => {
    try {
      const skillIds = selectedSkills.map((skill) => skill._id);
      const tagIds = selectedTags.map((tag) => tag._id);
      const { data } = await axios.post(
        `/talentPool/admission-request-selected/${id}`,
        {
          category_id: selectedCategory._id,
          userEmail: userData?.email,
          rejectionComment: rejectionComment,
          skills: skillIds,
          tags: tagIds,
          user_price: userPrice,
          is_top_candidate: isTopCandidate,
        },
        {
          headers: {
            authorization: `Bearer ${Marketer.token}`,
          },
        }
      );

      if (data?.success) {
        toast.success(data?.message, toastStyle);
      } else {
        toast.error(data?.message, toastStyle);
      }

      const updatedAdmissionRequests = admissionRequests?.filter((request) => {
        return data?.data?._id !== id; // Add 'return' statement
      });
      setAdmissionRequests(updatedAdmissionRequests);
    } catch (e) {
      console.log("The error is ", e);
    }
  };

  const handleRejectRequest = async (id) => {
    try {
      const { data } = await axios.post(
        `/talentPool/admission-request-rejected/${id}`,
        {},
        {
          headers: {
            authorization: `Bearer ${Marketer.token}`,
          },
        }
      );

      const updatedAdmissionRequests = admissionRequests.filter((request) => {
        return data?.data?._id !== id; // Add 'return' statement
      });
      setAdmissionRequests(updatedAdmissionRequests);
    } catch (e) {
      console.log("The error is ", e);
    }
  };

  const handleCancelButton = (id) => {
    setDropdownActivate({ [id]: false });
    setSkillSearchValue("");
    setTagSearchValue("");
    setSuggestedSkills([]);
    setSuggestedTags([]);
    setSelectedSkills([]);
    setSelectedTags([]);
    setIsTopCandidate(false);
    setUserPrice(selectedCategory?.price_per_user);
    setrejectionComment("");
  };
  return (
    <div className="requestingAdmissionContainer">
      {admissionRequests?.map((request) => {
        return (
          <>
            <div className="requestColumn">
              <div className="candidateInformation">
                <div className="request-header-prof">
                  <img
                    src={request?.user_id?.profilePhoto}
                    className="profile-img"
                  />

                  <p>
                    {request?.user_id?.name} |{" "}
                    <span>{request?.user_id?.email}</span>
                  </p>
                  <BsArrowUpRight />
                </div>

                <TwoButtonWrapper
                  firstComponent={
                    <img
                      src={Demography}
                      style={{ height: "20px", width: "20px" }}
                    />
                  }
                  backgroundColor={"white"}
                  color={"black"}
                  componentFunction={() =>
                    handleClick(request?.user_id?.resume)
                  }
                />

                <TwoButtonWrapper
                  firstComponent={
                    <img src={LinkedInLogo} className="icon20size" />
                  }
                  backgroundColor={"white"}
                  color={"black"}
                  componentFunction={() =>
                    handleClick(request?.user_id?.socialLinks?.linkedIn)
                  }
                />

                <TwoButtonWrapper
                  firstComponent={
                    <span class="material-symbols-outlined">chat</span>
                  }
                  backgroundColor={"white"}
                  color={"black"}
                  componentFunction={() =>
                    handleChatButton(request?._id, request?.chat_inbox)
                  }
                />

                <TwoButtonWrapper
                  firstComponent={
                    <MdOutlineThumbsUpDown></MdOutlineThumbsUpDown>
                  }
                  secondComponent={"Review assessments"}
                  backgroundColor={"white"}
                  color={"black"}
                ></TwoButtonWrapper>
              </div>

              <div className="selectButtons">
                <TwoButtonWrapper
                  firstComponent={<MdOutlineDone />}
                  backgroundColor={"white"}
                  color={"black"}
                  componentFunction={() => handleDropdown(request?._id)}
                />

                <TwoButtonWrapper
                  firstComponent={<RxCross2 />}
                  backgroundColor={"white"}
                  color={"black"}
                  componentFunction={() => handleRejectRequest(request?._id)}
                />
              </div>
            </div>

            {dropdownActivate[request?._id] && (
              <DropdownContentArea>
                <div className="inviteDetailContainer">
                  <div className="detailColumns">
                    <div className="verticalLabelInput">
                      <label htmlFor="userName">Name</label>
                      <input
                        type="text"
                        className="detailInput fullName"
                        placeholder="First Name + Last Name"
                        value={request?.user_id?.name}
                      />
                    </div>
                  </div>
                  <div className="detailColumns">
                    <div className="horizontalLabelInput">
                      <label htmlFor="userName">Custom Profile Pricing</label>
                      <input
                        type="number"
                        s
                        className="detailInput userPrice"
                        value={userPrice}
                        onChange={(e) => setUserPrice(e.target.value)}
                        // style={{ color: isTopCandidate ? "black" : "gray" }}
                      />
                    </div>

                    <div className="horizontalLabelInput">
                      <label htmlFor="userName">
                        Make them the top candidate in your pool
                      </label>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ToggleSwitch
                          isTopCandidate={isTopCandidate}
                          setIsTopCandidate={setIsTopCandidate}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="detailColumns">
                    <div className="verticalLabelInput">
                      <label htmlFor="addSkills">Add Skills</label>
                      <div className="skillAndCustomTags">
                        <div className="seachBarWithSuggestion">
                          <div className="searchBar">
                            <img src={magnify} alt="" />
                            <input
                              type="text"
                              placeholder="Add skills"
                              value={skillSearchValue}
                              onChange={(e) => handleSkillsSearch(e)}
                            />
                          </div>

                          {suggestedSkills.length > 0 &&
                            skillSearchValue.length > 0 && (
                              <div className="suggestionBox">
                                {suggestedSkills?.map((element) => {
                                  return (
                                    <div
                                      className="skillOrTagItem"
                                      style={{
                                        backgroundColor:
                                          element?.backgroundColor,
                                        border: `1px solid ${element?.borderColor}`,
                                      }}
                                      onClick={() =>
                                        selectSuggestedSkill(element)
                                      }
                                    >
                                      {element.name}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>

                        <div className="skillAndTagContainer">
                          {selectedSkills?.map((element) => {
                            return (
                              <div
                                className="skillOrTagItem"
                                style={{
                                  backgroundColor: element?.backgroundColor,
                                  border: `1px solid ${element?.borderColor}`,
                                }}
                              >
                                {element.name}
                                <RxCross2
                                  className="crossIcon"
                                  onClick={() => removeExistingSkill(element)}
                                ></RxCross2>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="verticalLabelInput">
                      <label htmlFor="customTags">Custom Tags</label>
                      <div className="skillAndCustomTags">
                        <div className="seachBarWithSuggestion">
                          <div className="searchBar">
                            <img src={magnify} alt="" />
                            <input
                              type="text"
                              placeholder="Add tags"
                              value={tagSeachValue}
                              onChange={(e) => handleTagsSearch(e)}
                              onKeyDown={(e) => addNewTag(e)}
                            />
                          </div>
                          {suggestedTags.length > 0 &&
                            tagSeachValue.length > 0 && (
                              <div className="suggestionBox">
                                {suggestedTags?.map((element) => {
                                  return (
                                    <div
                                      className="skillOrTagItem"
                                      style={{
                                        backgroundColor:
                                          element?.backgroundColor,
                                        border: `1px solid ${element?.borderColor}`,
                                      }}
                                      onClick={() =>
                                        selectSuggestedTag(element)
                                      }
                                    >
                                      {element.name}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>

                        <div className="skillAndTagContainer">
                          {selectedTags?.map((element) => {
                            return (
                              <div
                                className="skillOrTagItem"
                                style={{
                                  backgroundColor: element?.backgroundColor,
                                  border: `1px solid ${element?.borderColor}`,
                                }}
                              >
                                {element.name}
                                <RxCross2
                                  className="crossIcon"
                                  onClick={() => removeExistingTag(element)}
                                ></RxCross2>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="verticalLabelInput">
                    <label htmlFor="candidateComments">Comments</label>

                    <textarea
                      name="candidateComments"
                      id="candidateComments"
                      className="detailInput userComments"
                      rows="4"
                      value={rejectionComment}
                      onChange={(e) => setrejectionComment(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="inviteDetailButtons">
                    <TwoButtonWrapper
                      firstComponent={"Cancel"}
                      height={"45px"}
                      borderRadius={"10px"}
                      backgroundColor={"white"}
                      color={"black"}
                      componentFunction={() => handleCancelButton(request?._id)}
                    ></TwoButtonWrapper>

                    <TwoButtonWrapper
                      firstComponent={"Add"}
                      height={"45px"}
                      borderRadius={"10px"}
                      backgroundColor={"black"}
                      color={"white"}
                      componentFunction={() =>
                        handleSaveButton(request?._id, request?.user_id)
                      }
                    ></TwoButtonWrapper>
                  </div>
                </div>
              </DropdownContentArea>
            )}

            {chatButton[request?._id] && (
              <ChatBoxComponent
                chatMessages={chatMessages}
                setChatMessages={setChatMessages}
                user_id={Marketer?.user?._id}
                chatBoxId={request?.chat_inbox}
                type={"admission"}
              ></ChatBoxComponent>
            )}
          </>
        );
      })}
    </div>
  );
};
