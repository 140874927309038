import "./App.css";
import Footer from "./components/Footer/Footer";
import ProjectList from "./components/Brand/ProjectList";
import AgencyLogin from "./components/auth/AgencyLogin";
import Register from "./components/auth/Register";
import BrandLogin from "./components/auth/BrandLogin";
import BrandRegister from "./components/auth/BrandRegister";
import SearchPage from "./components/SearchPage/SearchPage";
import ServicePage from "./components/ServicePage";
import ContactUs from "./components/ContactUs";
import ForBrands from "./components/ForBrands";
import ForAgencies from "./components/ForAgencies";
import AboutUs from "./components/AboutUs";
import Campaign from "./components/campaigns/Campaign";
import CampaignProfile from "./components/campaigns/CampaignProfile";
// React-Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AgencyProfile from "./components/agency/AgencyProfile";
import PortfolioDetail from "./components/portdet/PortfolioDetail";
import Pricing from "./components/pricing/Pricing";
import ADashboard from "./components/agencydashboard/ADashboard";
import AOnboarding from "./components/agencydashboard/AOnboarding";
import UAcademy from "./components/uacademy/UAcademy";
import UAcademyRegister from "./components/uacademy/UAcademyRegister";
import AProposals from "./components/agencydashboard/AProposals";
import ANewBusiness from "./components/agencydashboard/ANewBusiness";
import BEditProfile from "./components/brandDashboard/BEditProfile";
import Bdashboard from "./components/brandDashboard/BDashboard";
import AEditProfile from "./components/agencydashboard/AEditProfile";
import ASidebar from "./components/agencydashboard/ASidebar";
import ASubmitReferences from "./components/agencydashboard/ASubmitReferences";
import ATeam from "./components/agencydashboard/ATeam";
import ATeamAddMemb from "./components/agencydashboard/ATeamAddMemb";
import AYourProfile from "./components/agencydashboard/AYourProfile";
import ScrollToTop from "./components/ScrollToTop";
import AProposalTemplate from "./components/agencydashboard/AProposalTemplate";
import Sapmain from "./components/sap/Sapmain";
import BProjectBrief from "./components/Brand/BProjectBrief";
import BNewProject from "./components/Brand/BNewProject";
import BNewProjectBrief from "./components/Brand/BNewProjectBrief";
import BrandProfile from "./components/Brand/BrandProfile";
import BUserProfile from "./components/Brand/BUserProfile";
import SapLogin from "./components/sap/SapLogin";
import SapAgenciesDataUpload from "./components/sap/SapAgenciesDataUpload";
import HomepageContent from "./components/HomePageContent";
import HeaderMarketPlace from "./components/HeaderMArketplace";
import AgencyContact from "./components/AgencyContact";
import BrandListing from "./components/Brand/BrandListing";
import BNewProjectForm from "./components/Brand/BNewProjectForm";
import Error404 from "./components/Error404";
import ForgotPassword from "./components/auth/ForgotPassword";
import ConfirmPassword from "./components/auth/ConfirmPassword";
import CaseStudyLayout from "./components/CaseStudyLayout";
import HomePageNew from "./components/HomePageNew/HomePageNew";
import HeaderNew from "./components/HeaderNew/HeaderNew";
import CaseStudy from "./components/CaseStudy/CaseStudy";
import CaseStudyCopy from "./components/CaseStudyCopy/CaseStudyCopy";
import UserProfile from "./components/UserProfile/UserProfile";
import CaseStudyListing from "./components/CaseStudyListing/CaseStudyListing";
import AgencyProfileNew from "./components/AgencyProfileNew/AgencyProfile";
import BrandProfileNew from "./components/brandProfile/brandProfile";
import MarketerDashb from "./components/MarketerDashb/MarketerDashb";
import UserLogin from "./components/auth/marketersLogin";
import UserRegister from "./components/auth/marketerRegister";
import { useEffect, useState } from "react";
import HomePageLatest from "./components/HomePageLatest/HomePageLatest";
import LandingPage from "./components/LandingPage/landingPage";
import HomePage from "./components/HomePageTP/HomePage";
import ThankYouPage from "./components/ThankYouPage/ThankYouPage";
import PaymentFailed from "./components/PaymentFailedPage/PaymentFailed";
import Terms from "./components/TermsAndCondn/terms";
import MarketersLogin from "./components/auth/marketersLogin";
import { TalentPoolUserConsent } from "./components/MarketerDashbCompo/Talent/TalentPoolUserConsent/TalentPoolUserConsent";
import { Pools } from "./components/Pools/Pools";
import { Mentors } from "./components/Mentors/Mentors";
import { ActivityThankYouPage } from "./components/ActivityThankYouPage/ActivityThankYouPage";
import NewHomePage from "./components/NewHomePage/NewHomePage";
import MentorProductPage from "./components/MentorProductPage/MentorProductPage";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { TourProviderContext } from "./context/TourProviderContext";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [refreshHeader, setRefreshHeader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardRoute, setDashboardRoute] = useState();
  const [selectedNavbarTab, setSelectedNavbarTab] = useState("dashboard");

  const loginHandler = (status) => {
    setLoggedIn(status);
  };

  const toggleRefresh = () => {
    setRefreshHeader(!refreshHeader);
  };

  const updateStateAsPerLocalStorage = () => {
    console.log("the markete iis ", localStorage.getItem("marketer"));
    console.log("the user is ", localStorage.getItem("user"));
    if (localStorage.getItem("marketer") !== null) {
      setLoggedIn(true);
      setDashboardRoute("/user-dashboard/profile");
    } else if (localStorage.getItem("user") !== null) {
      setLoggedIn(true);
      setDashboardRoute("/adashboard/aeditprofile");
    } else if (localStorage.getItem("branduser") !== null) {
      setLoggedIn(true);
      setDashboardRoute("/bdashboard/beditprofile");
    }
  };

  useEffect(() => {
    updateStateAsPerLocalStorage();
  }, []);

  useEffect(() => {
    updateStateAsPerLocalStorage();
  }, [refreshHeader]);

  const setLoadingHandler = (status) => {
    setIsLoading(status);
  };

  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <ToastContainer />
          {window.location.pathname.split("/")[1] !== "paymentsuccess" && (
            <HeaderNew
              dashboardRoute={dashboardRoute}
              setDashboardRoute={setDashboardRoute}
              selectedNavbarTab={selectedNavbarTab}
              setSelectedNavbarTab={setSelectedNavbarTab}
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
            />
          )}
          <ScrollToTop />
          <Switch>
            <Route path="/paymentsuccess" exact>
              <ThankYouPage />
            </Route>
            <Route path="/payment-failed" exact>
              <PaymentFailed />
            </Route>
            <Route path="/activitypaymentsuccess" exact>
              <ActivityThankYouPage />
            </Route>

            <Route path="/user-login" exact>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_UNISPADE_GOOGLE_CLIENT_ID}
              >
                <UserLogin toggleRefresh={toggleRefresh} />
              </GoogleOAuthProvider>

              <Footer />
            </Route>
            <Route path="/user-registration" exact>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_UNISPADE_GOOGLE_CLIENT_ID}
              >
                <UserRegister toggleRefresh={toggleRefresh} />
              </GoogleOAuthProvider>
              <Footer />
            </Route>
            {/* <Route path="/user-dashboard/:id">
            <MarketerDashb />
          </Route> */}
            <Route path="/user-dashboard/:id" exact>
              <TourProviderContext>
                <MarketerDashb />
              </TourProviderContext>
            </Route>
            <Route path="/pools" exact>
              <Pools />
            </Route>
            <Route path="/mentors" exact>
              <Mentors />
              <Footer setSelectedNavbarTab={setSelectedNavbarTab} />
            </Route>
            {/* <Route path="/brand-profile/:brandName">
            
            <BrandProfileNew />
            <Footer />
          </Route>
          <Route path="/agency-profile/:agencyName">
            <AgencyProfileNew />
          </Route>
          <Route path="/case-study-listing">
            
            <CaseStudyListing />
            <Footer />
          </Route> */}
            <Route path="/userprofile/:username/:tab?/:id?">
              <UserProfile />
            </Route>
            {/* <Route path="/case-studies/:id">
            
            <CaseStudy />
            <Footer></Footer>
          </Route>
          <Route path="/case-study/:id">
            
            <CaseStudyCopy />
            <Footer></Footer>
          </Route>

          <Route path="/casestudylayout/:caseStudyId/:index" exact>
            
            <CaseStudyLayout />
            <Footer></Footer>
          </Route> */}
            <Route path="/forgotpassword" exact>
              <ForgotPassword />
              <Footer></Footer>
            </Route>
            <Route path="/confirmpassword" exact>
              <ConfirmPassword />
              <Footer></Footer>
            </Route>
            {/* <Route path="/brandlisting" exact>
            
            <BrandListing />
            <Footer></Footer>
          </Route> */}

            {/* <Route path="/signup" exact>
             */}
            {/* Header When user is logged in*/}
            {/* <Headerlog /> */}
            {/* <Register></Register>
            <Footer />
          </Route> */}

            {/* <Route path="/aboutus" exact>
            
            <AboutUs />
            <Footer />
          </Route> */}
            {/* <Route path="/brandsignup" exact>
             */}
            {/* Header When user is logged in*/}
            {/* <Headerlog /> */}
            {/* <BrandRegister />
            <Footer />
          </Route>
          <Route path="/brandlogin" exact>
            
            <BrandLogin loginHandler={loginHandler} />
            <Footer />
          </Route> */}
            {/* <Route path="/homepage">
            // <HeaderNew loggedIn={loggedIn} />
            <HomePageNew />
            <Footer />
          </Route> */}

            {/* <Route path="/homepage">
            <HomePageLatest />
            <Footer />
          </Route> */}
            <Route path="/homepage">
              <HomePage />
              <Footer />
            </Route>
            <Route path="/loginSSObutton" exact>
              {/* <HeaderNew loggedIn={loggedIn} /> */}
              <loginSSObutton />
              <Footer></Footer>
            </Route>
            <Route path="/login" exact>
              <AgencyLogin />
              <Footer />
            </Route>
            {/* <Route path="/contactus" exact>
            {/* <HeaderNew loggedIn={loggedIn} />
            <ContactUs />
            <Footer />
          </Route> */}
            {/* <Route path="/search" exact>
            
            <SearchPage
              loggedIn={loggedIn}
              setLoadingHandler={setLoadingHandler}
            />
            {!isLoading && <Footer bgColor="#F7FAFD" />}
          </Route>
          <Route path="/search/:alphabet" exact>
            
            <SearchPage
              loggedIn={loggedIn}
              setLoadingHandler={setLoadingHandler}
            /> */}
            {/* {!isLoading && <Footer bgColor="#F7FAFD" />}
          </Route> */}
            <Route path="/sapagenciesdataupload" exact>
              <SapAgenciesDataUpload />
            </Route>
            {/*<Route path="/search/:service" exact>
            <Header headerbgblue />
            <SearchPage />
            <Footer />
          </Route>
          <Route path="/search/:service/:location" exact>
            <Header headerbgblue />
            <SearchPage />
            <Footer />
          </Route>*/}

            {/* <Route path="/servicepage" exact>
            
            <ServicePage />
            <Footer />
          </Route> */}
            {/* <Route path="/newprojectform">
            // <HeaderNew loggedIn={loggedIn} />
            <BNewProjectForm />
          </Route> */}
            {/* <Route exact path="/agencyprofile/contact/:agencyId">
            
            <AgencyContact></AgencyContact>
            <Footer />
          </Route>
          <Route path="/agencyprofile/newprojectform/:agencyId">
            
            <BNewProjectForm />
          </Route>
          <Route path="/agencyprofile/:agencyName" exact>
            
            <AgencyProfileNew />
            <Footer />
          </Route>
          <Route path="/agencyprofile/:agencyName/:agencyId" exact>
            
            <AgencyProfileNew />
            <Footer />
          </Route> */}

            {/* <Route path="/portfoliodetail/:caseStudyId/:index" exact>
            
            <PortfolioDetail />
            <Footer />
          </Route> */}
            {/* <Route path="/forbrands" exact>
            
            <ForBrands />
            <Footer />
          </Route> */}
            {/* <Route path="/foragencies" exact>
            
            <ForAgencies />
            <Footer />
          </Route> */}
            {/* <Route path="/campaign/:agencyId" exact>
            
            <CampaignProfile />
            <Footer />
          </Route> */}
            {/* <Route path="/campaigns" exact>
            
            <Campaign />
            <Footer />
          </Route> */}
            {/* <Route path="/pricing" exact>
            
            <Pricing />
            <Footer />
          </Route> */}
            {/* <Route path="/uacademy" exact>
            <UAcademy />
          </Route> */}
            {/* <Route path="/uacademyregister/:userId" exact>
            <UAcademyRegister />
          </Route> */}
            {/* <Route path="/adashboard" exact>
            <ADashboard />
          </Route>
          <Route path="/adashboard/:agencyId" exact>
            <ADashboard />
          </Route>

          <Route path="/aonboarding" exact>
            <AOnboarding />
          </Route> */}
            {/* <Route path="/aproposals" exact>
            <AProposals />
          </Route>
          <Route path="/anewbusiness" exact>
            <ANewBusiness />
          </Route> */}
            {/* <Route path="/aeditprofile/:_id" exact>
            <div className="adashboard__cont">
              <ASidebar />
              <AEditProfile />
            </div> */}
            {/* </Route>
          <Route path="/bdashboard" exact>
            <Bdashboard />
          </Route>
          <Route path="/bdashboard/:dashboardPage" exact>
            <Bdashboard />
          </Route> */}
            {/* <Route path="/asubmitreferences" exact>
            <ASubmitReferences />
          </Route> */}
            {/* <Route path="/ateam" exact>
            <ATeam />
          </Route> */}
            {/* <Route path="/ateam/addMemb" exact>
            <ATeamAddMemb />
          </Route>
          <Route path="/ayourprofile" exact>
            <AYourProfile />
          </Route>
          <Route path="/aproposaltemplate" exact>
            <AProposalTemplate />
          </Route> */}
            <Route path="/sap">
              <Sapmain />
            </Route>
            <Route path="/saplogin" exact>
              <SapLogin />
            </Route>
            {/* <Route path="/projectlist" exact>
            
            <ProjectList />
          </Route> */}

            {/* <Route path="/projectbrief">
            
            <BProjectBrief />
          </Route> */}
            {/* <Route path="/newproject">
            
            <BNewProject />
          </Route> */}

            {/* <Route path="/newprojectbrief">
            
            <BNewProjectBrief />
          </Route> */}

            {/* <Route path="/brandprofile/:brandId">
            
            <BrandProfile />
          </Route>
          <Route path="/contact-us">
            <LandingPage />
            <Footer />
          </Route>
          <Route path="/brandprofile">
            
            <BrandProfile />
          </Route>
          <Route path="/buserprofile"> */}
            {/*             
            <BUserProfile />
          </Route> */}
            <Route path="/termsandconditions" exact>
              <Terms />
            </Route>

            <Route path="/google-login-success" exact>
              <MarketersLogin isGoogleLogin={true} />
            </Route>

            {/* <Route path="/marketplace" exact>
            <HeaderMarketPlace />
            <HomepageContent />
            <Footer />
          </Route> */}
            <Route path="/talent_pool_user_consent" exact>
              <TalentPoolUserConsent />
            </Route>

            {/* <Route path="/:servicePageKeyword" exact> */}

            {/* <ServicePage /> */}
            {/* <SearchPage
              servicePage
              loggedIn={loggedIn}
              setLoadingHandler={setLoadingHandler}
            />
            {!isLoading && <Footer bgColor="#F7FAFD" />}
          </Route> */}

            <Route path="/mentor/products">
              <MentorProductPage />
              <Footer />
            </Route>
            <Route path="/" exact>
              <NewHomePage />
              <Footer />
            </Route>

            <Route component={Error404}>
              <Error404 />
              <Footer></Footer>
            </Route>
          </Switch>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
