import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./talentPool.css";
import axios from "../../../../axios";
import Loader from "../../../loader/Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SingleTalentPool from "./SingleTalentPool";
import { toast } from "react-toastify";
import toastStyle from "../../../../configs/toastConfig";
import { Helmet } from "react-helmet-async";

const TalentPool = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [isPoolOpen, setIsPoolOpen] = useState({});
  const [detailedTalentPool, setDetailedTalentPool] = useState(null);
  const [totalMember, setTotalMember] = useState(0);
  const [categoryViewCount, setCategoryViewCount] = useState({});

  const [talentPool, setTalentPool] = useState([]);
  const [firstOpenCategoryId, setFirstOpenCategoryId] = useState("");
  const location = useLocation();
  const history = useHistory();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  // const sendClickData = async () => {
  //   try {
  //     await axios.post(
  //       "/talentPool/set_category_pool_views/",
  //       categoryViewCount
  //     );
  //     setCategoryViewCount({}); // Reset count after sending data
  //   } catch (err) {
  //     console.log("the error is ", err);
  //   }
  // };

  // useEffect(() => {
  //   const handleBeforeUnload = async (event) => {
  //     if (hasSentRequestRef.current) {
  //       // Step 2: Check if the request has already been sent
  //       return;
  //     }

  //     hasSentRequestRef.current = true; // Step 3: Set the flag to true to prevent future requests

  //     try {
  //       await axios.post(
  //         "/talentPool/set_category_pool_views/",
  //         categoryViewCount
  //       );
  //       setCategoryViewCount({}); // Reset count after sending data
  //     } catch (err) {
  //       console.log("the error is ", err);
  //     }
  //   };

  //   const handleVisibilityChange = () => {
  //     if (hasSentRequestRef.current) {
  //       // Step 2: Check if the request has already been sent
  //       return;
  //     }

  //     if (document.visibilityState === "hidden") {
  //       sendClickData();
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [categoryViewCount]);

  useEffect(() => {
    if (!location.search) {
      //   setIsPoolOpen({ [firstOpenCategoryId]: true });
      fetchTalentPoolData();
    }
    if (location.search) {
      const paramId = location?.search?.split("?")[1].split("=")[1];
      setIsPoolOpen({ [paramId]: true });
      fetchTalentPoolData();
    }
  }, [location.search, firstOpenCategoryId]);

  // function handleShowOrHidePool(categId) {
  //   setIsPoolOpen((prevState) => ({
  //     ...prevState,
  //     [categId]: !prevState[categId],
  //   }));

  //   setCategoryViewCount((prevState) => ({
  //     ...prevState,
  //     [categId]: (prevState[categId] || 0) + 1,
  //   }));
  // }

  const fetchTalentPoolData = async () => {
    try {
      console.log("Ye toh first hai");

      let { data } = await axios.get(
        `/talentPool/get_talent_pool_categories/${user._id}`
      );
      const talentPoolData = [];
      let totMember = 0;

      let messageStatusInfo = [];
      if (Marketer !== null) {
        messageStatusInfo = await axios.get(
          `/nudges/get-all-nudges-status/${user._id}`,
          {
            headers: { authorization: "Bearer " + Marketer.token },
          }
        );
      }
      console.log("Ye toh second hai");
      let talentPaymentInfo = [];
      if (Marketer !== null) {
        talentPaymentInfo = await axios.get(
          `/talent-payment-status/${user._id}`,
          {
            headers: { authorization: "Bearer " + Marketer.token },
          }
        );
      }

      console.log("the payment data ", talentPaymentInfo);

      await Promise.all(
        data?.map(async (category) => {
          const categoryData = {
            categoryName: "",
            categoryPrice: "",
            talentPoolMembers: {},
          };
          setLoading(true);
          try {
            if (Marketer !== null) {
              data = await axios.post(
                "/talentPool/get_Talent_Pool_User_For_Verified_User",
                {
                  category_id: category._id,
                  invited_by: user._id,
                },
                {
                  headers: { authorization: "Bearer " + Marketer.token },
                }
              );

              console.log("the coming data is ", data?.data);

              // This mapping is used to market, message_status, and payment_status for each user seperately
              data?.data?.users?.map((poolMember) => {
                const targetMessage = messageStatusInfo?.data?.find(
                  (message) =>
                    message.category_id === category._id &&
                    message.receiver_id === poolMember.user_id._id
                );

                const targetTalent = talentPaymentInfo?.data?.find(
                  (talent) => talent.member_id === poolMember.user_id._id
                );

                if (targetMessage !== undefined) {
                  poolMember.user_id["message_status"] =
                    targetMessage.current_status; // pushing status to talentPoolUsers->user_id object
                }

                if (targetTalent !== undefined) {
                  poolMember.user_id["payment_status"] =
                    targetTalent.payment_paid; // pushing status to talentPoolUsers->user_id object
                }
              });

              categoryData.talentPoolMembers = data.data.users;
              categoryData.verified = data.data.verified;
              totMember = totMember + data.data.users.length;
            } else {
              data = await axios.post(
                "/talentPool/get_talent_pool_users_for_public_profile",
                {
                  category_id: category._id,
                }
              );
              categoryData.talentPoolMembers = data.data;
              categoryData.verified = false;
              totMember = totMember + data.data.length;
            }
          } catch (error) {
            if (error.response?.status === 401) {
              localStorage.removeItem("marketer");
              data = await axios.post(
                "/talentPool/get_talent_pool_users_for_public_profile",
                {
                  category_id: category._id,
                }
              );
              categoryData.talentPoolMembers = data.data;
              categoryData.verified = false;
              totMember = totMember + data.data.length;
            }
          }

          categoryData.categoryName = category.name;
          categoryData.categoryPrice = category.price;
          categoryData.categoryPricePerUser = category?.price_per_user;
          talentPoolData.push(categoryData);
        })
      );

      setIsPoolOpen({
        [talentPoolData[0]?.talentPoolMembers[0]?.category_id]: true,
      });
      setTalentPool(talentPoolData);
      setTotalMember(totMember);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleShowPool = (categId) => {
    setIsPoolOpen((prevState) => ({
      [categId]: true,
    }));

    const something = talentPool
      ?.filter((e) => e?.talentPoolMembers[0]?.category_id === categId)
      .map((element) => {
        if (element?.talentPoolMembers[0]?.category_id === categId) {
          return element;
        }
      });

    setDetailedTalentPool(something[0]);

    setCategoryViewCount((prevState) => ({
      ...prevState,
      [categId]: (prevState[categId] || 0) + 1,
    }));
  };

  const handleHidePool = (categId) => {
    setIsPoolOpen((prevState) => ({
      [categId]: false,
    }));

    setDetailedTalentPool(null);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : detailedTalentPool ? (
        <SingleTalentPool
          user={user}
          talentPool={talentPool}
          detailedTalentPool={detailedTalentPool}
          setDetailedTalentPool={setDetailedTalentPool}
          isPoolOpen={isPoolOpen}
          handleHidePool={handleHidePool}
        />
      ) : (
        <>
          <div className="userProfileTalentPool">
            {talentPool?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="singleCateogory"
                  onClick={() =>
                    handleShowPool(data?.talentPoolMembers[0]?.category_id)
                  }
                >
                  <div className="categoryInfo">
                    <span className="categoryName">{data?.categoryName}</span>
                    <span className="theExtraBar">{" | "}</span>
                    <span className="categoryNumber">
                      {data?.talentPoolMembers.length}
                    </span>
                  </div>

                  <div className="bottomCategoryInfo">
                    <div className="categoryPrice">
                      <span class="material-symbols-outlined">
                        currency_rupee
                      </span>
                      {data?.categoryPricePerUser}
                    </div>
                    <button
                      className="viewProfileButton"
                      onClick={() =>
                        handleShowPool(data?.talentPoolMembers[0]?.category_id)
                      }
                    >
                      View Profile
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="userProfileTalentPoolMobile">
            {talentPool.map((data) => (
              <div key={data._id} className="singleCategoryMobile">
                <div className="categoryHeader">
                  <div className="categoryDetails">
                    <span className="categoryName">{data?.categoryName}</span>
                  </div>
                  <div className="categoryMemberNumber">
                    <span>{data?.talentPoolMembers.length}</span>
                  </div>
                </div>
                {/* <div className="profile-mob">
                  <div className="profile-name">
                    <img  alt="Profile" className="profile-img" />
                    <p>Rahul Sharma</p>
                  </div>

                </div>
                <div>
                  <div className="userProfiles">
                    <div className="userProfiles-relative">
                      <img
                        alt="person1"
                        className="person1-logo"
                      />
                      <img
                        alt="person2"
                        className="person2-logo"
                      />
                      <img
                        alt="person3"
                        className="person3-logo"
                      />
                    </div>
                    <span>{data.user_number} profiles</span>
                  </div>
                </div> */}

                <div className="categoryFooter">
                  <span className="categoryPrice">
                    ₹{data?.categoryPricePerUser}
                  </span>
                  <button
                    className="viewTalentPoolButton"
                    onClick={() =>
                      handleShowPool(data?.talentPoolMembers[0]?.category_id)
                    }
                  >
                    View Talent Pool
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default TalentPool;
