import { React } from "react";
import "./ModalForm.css";
import { MdOutlineClose } from "react-icons/md";

export const ModalForm = ({
  onClose,
  children,
  onSubmit,
  isEdit,
  isLoader,
  dataView = false
}) => {
  return (
    <div className="modal-form-container" onClick={(e) => e.stopPropagation()}>
      <button onClick={onClose} className="modal-form-close-button">
        <MdOutlineClose />
      </button>
      <div className="modal-form-content">{children}</div>
      {!dataView && (
      <div className="modal-form-footer">
      <button
        onClick={onClose}
        className="modal-form-cancel-button"
        disabled={isLoader}
      >
        Cancel
      </button>
      <button
        onClick={onSubmit}
        className="modal-form-submit-button"
        disabled={isLoader}
      >
        {isEdit ? "Update" : "Submit "}
      </button>
    </div>
      )}

    </div>
  );
};
