import React, { useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import "./SapDiscountCoupon.css";
import { FaSearch } from "react-icons/fa";
import { Overlay } from "../../../atoms/Overlay/Overlay";
import { ModalForm } from "../../../atoms/ModalForm/ModalForm";
import { useFormik } from "formik";
import { InputField } from "../../../atoms/Input/InputField";
import { DropdownField } from "../../../atoms/DropdownField/DropdownField";
import axios from "../../../axios";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import toastStyle from "../../../configs/toastConfig";
import moment from "moment";
import * as Yup from "yup";

ModuleRegistry.registerModules([AllCommunityModule]);

export const SapDiscountCoupon = () => {
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [dataView, setDataView] = useState(false);

  const gridRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").trim().uppercase(),
    usedFor: Yup.string()
      .oneOf(["MENTORING_SESSION"], "Invalid usage context")
      .required("Used For is required"),
    isActive: Yup.boolean().default(true),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date(),
    discountInPercentage: Yup.number()
      .min(0, "Discount percentage cannot be less than 0")
      .max(100, "Discount percentage cannot be more than 100"),
    discountInAmount: Yup.number().min(
      0,
      "Discount amount cannot be less than 0"
    ),
    usageLimitPerUserGlobal: Yup.number()
      .default(1)
      .min(1, "Global usage limit cannot be less than 1"),
    usageLimitPerUserPerMentor: Yup.number()
      .default(1)
      .min(1, "Per mentor usage limit cannot be less than 1"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      usedFor: "MENTORING_SESSION",
      isActive: true,
      startDate: "",
      endDate: "",
      discountInPercentage: 0,
      discountInAmount: 0,
      usageLimitPerUserGlobal: 1,
      usageLimitPerUserPerMentor: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (isEdit) {
        const id = formik.values._id;
        await updateDiscountCoupon(id, values);
      } else {
        await createNewDiscountCoupon(values);
      }
      onClose();
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoader(true);
        const { data } = await axios.get("/discount-coupon/get");
        data.data.startDate = moment(data?.data?.startDate).format(
          "YYYY-MM-DD"
        );
        data.data.endDate = moment(data?.data?.endDate).format("YYYY-MM-DD");
        setRowData(data?.data);
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          toast.error(data?.message || "Something went wrong", toastStyle);
        } else {
          toast.error("Network error or server is unreachable", toastStyle);
        }
      } finally {
        setIsLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      setColDefs([
        { field: "name", headerName: "Name", sortable: true, flex: 2 },
        { field: "usedFor", headerName: "Used For", sortable: true, flex: 2 },
        { field: "isActive", headerName: "Active", sortable: true, flex: 1 },
        {
          field: "startDate",
          headerName: "Start Date",
          sortable: true,
          flex: 1.5,
          valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
        },
        {
          field: "endDate",
          headerName: "End Date",
          sortable: true,
          flex: 1.5,
          valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
        },
        {
          field: "discountInPercentage",
          headerName: "Discount (%)",
          sortable: true,
          flex: 1,
        },
        {
          field: "discountInAmount",
          headerName: "Discount (₹)",
          sortable: true,
          flex: 1.5,
        },
        // {
        //   field: "usageLimitPerUserGlobal",
        //   headerName: "Global Usage Limit",
        //   sortable: true,
        //   flex: 1,
        // },
        // {
        //   field: "usageLimitPerUserPerMentor",
        //   headerName: "Per Mentor Usage Limit",
        //   sortable: true,
        //   flex: 1,
        // },
        {
          headerName: "Actions",
          cellRenderer: (params) => (
            <div className="table-actions">
              <FaEye
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                onClick={(e) => {
                  openViewForm(params.data);
                }}
              />
              <FaRegEdit
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                onClick={(e) => {
                  openEditForm(params.data);
                }}
              />
              <MdDelete
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                onClick={(e) => {
                  deleteDiscountCoupon(params.data._id);
                }}
              />
            </div>
          ),
          flex: 1,
        },
      ]);
    } catch (error) {
      console.log("Error in setting column definitions");
    }
  }, [rowData]);

  const createNewDiscountCoupon = async (newCoupon) => {
    try {
      setIsLoader(true);
      const { data } = await axios.post("/discount-coupon/create", newCoupon);
      if (data?.success) {
        setRowData([...rowData, data?.data]);
        toast.success(data?.message, toastStyle);
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data?.message || "Something went wrong", toastStyle);
      } else {
        toast.error("Network error or server is unreachable", toastStyle);
      }
    } finally {
      setIsLoader(false);
    }
  };

  const updateDiscountCoupon = async (id, newCoupon) => {
    try {
      setIsLoader(true);
      const { data } = await axios.put(
        `/discount-coupon/update/${id}`,
        newCoupon
      );
      if (data?.success) {
        const updatedRowData = rowData.map((row) =>
          row._id === id ? data?.data : row
        );
        setRowData([...updatedRowData]);
        toast.success(data?.message, toastStyle);
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data?.message || "Something went wrong", toastStyle);
      } else {
        toast.error("Network error or server is unreachable", toastStyle);
      }
    } finally {
      setIsLoader(false);
    }
  };

  const deleteDiscountCoupon = async (id) => {
    try {
      setIsLoader(true);
      const { data } = await axios.delete(`/discount-coupon/delete/${id}`);
      if (data?.success) {
        const pendingData = rowData.filter((row) => row._id !== id);
        setRowData(pendingData);
        toast.success(data?.message, toastStyle);
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        toast.error(data?.message || "Something went wrong", toastStyle);
      } else {
        toast.error("Network error or server is unreachable", toastStyle);
      }
    } finally {
      setIsLoader(false);
    }
  };

  const openEditForm = (data) => {
    formik.setValues(data);
    setIsEdit(true);
    setOpenModel(true);
    setDataView(false);
  };

  const onFilterTextBoxChanged = (e) => {
    gridRef.current.api.setGridOption("quickFilterText", e.target.value);
  };

  const onClose = () => {
    setDataView(false);
    setOpenModel(false);
    setIsEdit(false);
    formik.resetForm();
  };

  const openViewForm = (data) => {
    console.log("the data is ", data);
    formik.setValues(data);
    setDataView(true);
    setOpenModel(true);
  };

  return (
    <div className="discountCouponContainer">
      {openModel && (
        <Overlay onClose={() => onClose()}>
          <ModalForm
            onClose={() => onClose()}
            onSubmit={formik.handleSubmit}
            isEdit={isEdit}
            isLoader={isLoader}
            dataView={dataView}
          >
            <InputField
              label_name={"Name"}
              name={"name"}
              type={"text"}
              formik={formik}
              placeholder={"Enter coupon name"}
              readOnly={dataView}
            />
            <InputField
              label_name={"Used For"}
              name={"usedFor"}
              type={"text"}
              formik={formik}
              placeholder={"Enter usage context"}
              readOnly={dataView}
            />
            <InputField
              label_name={"Active"}
              name={"isActive"}
              type={"checkbox"}
              formik={formik}
              readOnly={dataView}
            />
            <InputField
              label_name={"Start Date"}
              name={"startDate"}
              type={"date"}
              formik={formik}
              readOnly={dataView}
            />
            <InputField
              label_name={"End Date"}
              name={"endDate"}
              type={"date"}
              formik={formik}
              readOnly={dataView}
            />
            <InputField
              label_name={"Discount (%)"}
              name={"discountInPercentage"}
              type={"number"}
              formik={formik}
              placeholder={"Enter discount percentage"}
              readOnly={dataView}
            />
            <InputField
              label_name={"Discount (₹)"}
              name={"discountInAmount"}
              type={"number"}
              formik={formik}
              placeholder={"Enter discount amount"}
              readOnly={dataView}
            />
            <InputField
              label_name={"Global Usage Limit"}
              name={"usageLimitPerUserGlobal"}
              type={"number"}
              formik={formik}
              placeholder={"Enter usage limit"}
              readOnly={dataView}
            />
            <InputField
              label_name={"Per Mentor Usage Limit"}
              name={"usageLimitPerUserPerMentor"}
              type={"number"}
              formik={formik}
              placeholder={"Enter usage limit"}
              readOnly={dataView}
            />
          </ModalForm>
        </Overlay>
      )}
      <div className="discountCouponHeader">
        <div className="searchContainer">
          <FaSearch className="searchIcon" />
          <input
            type="text"
            className="searchInput"
            placeholder="Search..."
            onChange={(e) => onFilterTextBoxChanged(e)}
          />
        </div>
        <button
          className="addButton"
          onClick={() => setOpenModel(true)}
          disabled={isLoader}
        >
          Add New Coupon
        </button>
      </div>
      {rowData?.length > 0 && colDefs?.length > 0 ? (
        <div style={{ width: "100%", textAlign: "left" }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={{
              flex: 1,
              cellStyle: {
                fontSize: "14px",
                fontWeight: "600",
              },
            }}
            pagination={true}
            paginationPageSizeSelector={[5, 10, 15]}
            paginationPageSize={5}
            domLayout="autoHeight"
          />
        </div>
      ) : (
        <p>Loading Grid...</p>
      )}
    </div>
  );
};
