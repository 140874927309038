import React, { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import "./SapSessionTemplate.css";
import { FaSearch } from "react-icons/fa";
import { Overlay } from "../../../atoms/Overlay/Overlay";
import { ModalForm } from "../../../atoms/ModalForm/ModalForm";
import { useFormik } from "formik";
import { InputField } from "../../../atoms/Input/InputField";
import { DropdownList } from "../../../atoms/DropdownList/DropdownList";
import { DropdownField } from "../../../atoms/DropdownField/DropdownField";
// import axios from "axios";
import axios from "../../../axios";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import toastStyle from "../../../configs/toastConfig";
import * as Yup from "yup";

ModuleRegistry.registerModules([AllCommunityModule]);

const RolesList = [
  "Associate Product Manager (APM)",
  "Junior Product Manager (Jr. PM)",
  "Product Manager (PM)",
  "Senior Product Manager (Sr. PM)",
  "Staff Product Manager",
  "Product Lead / Lead Product Manager",
  "Product Director / Group Product Manager",
  "Principal Product Manager",
  "VP or SVP Product / Head of Product",
  "CPO / Chief Product Officer",
];

const ExpertiseIndustryData = {
  "Product Management": [
    "FinTech",
    "eCommerce & Quick Commerce",
    "AI",
    "Healthcare",
    "Entertainment & Streaming",
    "Logistics & Supply Chain",
    "Industrial Agnostic",
  ],
  Marketing: [
    "FinTech",
    "eCommerce & Quick Commerce",
    "AI",
    "Healthcare",
    "Entertainment & Streaming",
    "Logistics & Supply Chain",
    "Industrial Agnostic",
  ],
};

export const SapSessionTemplate = () => {
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const gridRef = useRef(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const formik = useFormik({
    initialValues: {
      position: "",
      type: "Video Call",
      title: "",
      duration: "",
      price: null,
      description: "",
      sessionFocus: "",
      sessionIndustry: "",
    },
    validationSchema: Yup.object({
      position: Yup.string().required("Position is required"),
      type: Yup.string().required("Type is required"),
      title: Yup.string().required("Title is required"),
      duration: Yup.string().required("Duration is required"),
      price: Yup.number()
        .required("Price is required")
        .positive("Price must be positive"),
      description: Yup.string(),
      sessionFocus: Yup.string().required("Session Focus is required"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const id = formik.values._id;
        await updateSessionTemplate(id, values);
      } else {
        await createNewSessionTemplate(values);
      }
      onClose();
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoader(true);
        const { data } = await axios.post("/mentoring/get-all-session-template", {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        });
        setRowData(data?.data);
      } catch (error) {
      } finally {
        setIsLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      setColDefs([
        {
          field: "position",
          headerName: "Position",
          sortable: true,
          flex: 1,
        },
        { field: "title", headerName: "Title", sortable: true, flex: 1 },
        {
          field: "duration",
          headerName: "Duration (min)",
          sortable: true,
          flex: 1,
        },
        { field: "price", headerName: "Price (₹)", sortable: true, flex: 1 },
        {
          field: "sessionFocus",
          headerName: "Session Focus",
          sortable: true,
          flex: 2,
        },
        {
          field: "sessionIndustry",
          headerName: "Industry",
          sortable: true,
          flex: 2,
        },
        {
          headerName: "Actions",
          cellRenderer: (params) => (
            <div className="table-actions">
              <FaRegEdit
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                onClick={() => openEditForm(params.data)}
              />
              <MdDelete
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                onClick={() => deleteSessionTemplate(params.data._id)}
              />
            </div>
          ),
          flex: 1,
        },
      ]);
    } catch (error) {
      console.log("Error in setting column definitions");
    }
  }, [rowData]);

  const createNewSessionTemplate = async (newSession) => {
    try {
      setIsLoader(true);
      const { data } = await axios.post(
        "/mentoring/session-template",
        newSession,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );
      // return data;
      if (data?.success) {
        setRowData([...rowData, data?.data]);
        toast.success(data?.message, toastStyle);
      }
    } catch (error) {
      console.log("Error in creating new session template");
    } finally {
      setIsLoader(false);
    }
  };

  const updateSessionTemplate = async (id, newSession) => {
    try {
      setIsLoader(true);
      const { data } = await axios.put(
        `/mentoring/session-template/${id}`,
        newSession,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );
      // return data;
      if (data?.success) {
        console.log("Row data before update", rowData);
        const updatedRowData = rowData.map((row) =>
          row._id === id ? data?.data : row
        );
        setRowData([...updatedRowData]);
        toast.success(data?.message, toastStyle);
      }
    } catch (error) {
      console.log("Error in updating session template");
    } finally {
      setIsLoader(false);
    }
  };

  const deleteSessionTemplate = async (id) => {
    try {
      setIsLoader(true);
      const { data } = await axios.delete(`/mentoring/session-template/${id}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });

      if (data?.success) {
        const pendingData = rowData.filter((row) => row._id !== id);
        setRowData(pendingData);
        toast.success(data?.message, toastStyle);
      }
    } catch (error) {
      console.log("Error in deleting session template");
    } finally {
      setIsLoader(false);
    }
  };

  const openEditForm = (data) => {
    formik.setValues(data);
    setIsEdit(true);
    setOpenModel(true);
  };
  const onFilterTextBoxChanged = (e) => {
    gridRef.current.api.setGridOption("quickFilterText", e.target.value);
  };

  const onClose = () => {
    setOpenModel(false);
    setIsEdit(false);
    formik.resetForm();
  };

  return (
    <div className="sessionTemplateContainer">
      {openModel && (
        <Overlay onClose={() => onClose()}>
          <ModalForm
            onClose={() => onClose()}
            onSubmit={formik.handleSubmit}
            isEdit={isEdit}
            isLoader={isLoader}
          >
            <DropdownField
              label_name={"Position"}
              name={"position"}
              type={"text"}
              value={formik.values.position}
              placeholder={"Select the Role/Position"}
              formik={formik}
              options={RolesList}
            />

            <InputField
              label_name={"Title"}
              name={"title"}
              type={"text"}
              formik={formik}
              placeholder={"Enter title of session"}
            />
            <InputField
              label_name={"Duration"}
              name={"duration"}
              type={"text"}
              formik={formik}
              placeholder={"Enter duration in Minutes"}
              placeholderInfo={"In Minutes"}
            />
            <InputField
              label_name={"Price"}
              name={"price"}
              type={"number"}
              formik={formik}
              placeholder={"Enter price in ₹"}
              placeholderInfo={"In ₹"}
            />
            <InputField
              label_name={"Description"}
              name={"description"}
              type={"text"}
              formik={formik}
              placeholder={"Enter description"}
            />
            <DropdownField
              label_name={"Focus"}
              name={"sessionFocus"}
              type={"text"}
              value={formik.values.sessionFocus}
              placeholder={"Select the focus"}
              formik={formik}
              options={Object.keys(ExpertiseIndustryData)}
            />
            <DropdownField
              label_name={"Industry"}
              name={"sessionIndustry"}
              type={"text"}
              value={formik.values.sessionIndustry}
              placeholder={"Select the Industry"}
              formik={formik}
              options={ExpertiseIndustryData[formik.values.sessionFocus]}
            />
          </ModalForm>
        </Overlay>
      )}
      <div className="sessionTemplateHeader">
        <div className="searchContainer">
          <FaSearch className="searchIcon" />
          <input
            type="text"
            className="searchInput"
            placeholder="Search..."
            onChange={(e) => onFilterTextBoxChanged(e)}
          />
        </div>

        <button
          className="addButton"
          onClick={() => setOpenModel(true)}
          disabled={isLoader}
        >
          Add New Template
        </button>
      </div>
      {rowData?.length > 0 && colDefs?.length > 0 ? (
        <div style={{ width: "100%", textAlign: "left" }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={{
              flex: 1,
              cellStyle: {
                fontSize: "14px",
                fontWeight: "600",
              },
            }}
            pagination={true}
            paginationPageSizeSelector={[5, 10, 15]}
            paginationPageSize={5}
            domLayout="autoHeight"
          />
        </div>
      ) : (
        <p>Loading Grid...</p>
      )}
    </div>
  );
};
