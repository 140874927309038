import React, { useState } from "react";
import "../../styles/marketersLogin.css";
import axios from "../../axios.js";
import { useHistory, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { GoogleLogin } from "@react-oauth/google";
import toastStyle from "../../configs/toastConfig.js";

function MarketersLogin(props) {
  const history = useHistory();
  const location = useLocation();
  const toast_style = {
    position: "bottom-right",
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    width: "10rem",
  };

  const [submitbtn, setSubmitbtn] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  const [newMarketer, setNewMarketer] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setNewMarketer((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const verifyValidInfo = () => {
    const regeX = /[a-z0-9._%+-]+@[a-z0-9-]+[.]+[a-z]{2,5}$/;
    const { name, username, email, password, confirmPassword } = newMarketer;
    if (!name || !email || !password || !username || !confirmPassword) {
      return "Please Fill all the mandatory Details";
    } else if (!regeX.test(email)) {
      return "Given email is invalid";
    } else if (password !== confirmPassword) {
      return "Password and Confirm Password didn't match";
    }
    return true;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitbtn(true); // loading icon starts
    if (!verifyOtp) {
      let validInfo = verifyValidInfo();
      if (validInfo !== true) {
        // toast error
        toast.error(validInfo, toast_style);
      } else {
        try {
          const { data } = await axios.post(
            "/marketers/userRegistration",
            newMarketer
          );
          toast.success("Account created successfully");
          history.push("/user-login");
          // setVerifyOtp(true);
          // toast.success(
          //   "An OTP has been sent to your email ID, please verify your account."
          // );
        } catch (error) {
          // toast error
          toast.error(error.response.data.message, toast_style);
        }
      }
    } else {
      try {
        const { data } = await axios.post(
          "/marketers/verifyAccount",
          newMarketer
        );
        // toast success wait for 3 seconds and redirect to login
        // setTimeout(() => {
        //   location.href="/user-login"
        // }, 4000);
        toast.success("Account created successfully");
        history.push("/user-login");
      } catch (error) {
        // toast error
        toast.error(error.response.data.message, toast_style);
      }
    }
    setSubmitbtn(false); // loading icon ends
  };
  const handleLoginSuccess = async (response) => {
    const { data } = await axios.post("/marketers/register-using-google", {
      token: response.credential,
    });

    if (data?.success) {
      localStorage.setItem("marketer", JSON.stringify(data?.data));
      history.push("/user-dashboard/profile");
      props.toggleRefresh();
    } else {
      toast.error(data?.message, toastStyle);
    }
  };

  const handleLoginError = () => {
    console.log("Login Error: Something went wrong during Google login.");
    // Handle any error that occurs during login
  };

  return (
    <div className="Marketerslogin__cont">
      <Helmet>
        <title>
          Register to Find Expert Mentors for Career & Business Success
        </title>
        <meta
          name="description"
          content="Sign up to connect with top mentors in your field. Start your mentorship journey with Unispade and achieve your personal and professional goals."
        />
        <link rel="canonical" href="https://unispade.com/user-registration" />
      </Helmet>
      <div className="login">
        <h1 className="fw__600 logreg__title">
          User <span className="color__black"> Registration</span>
        </h1>
        <div className="uLoginForm">
          <form className="login__form" onSubmit={submitHandler}>
            <label>
              Name&nbsp;<sup className="mandatoryFieldSign">*</sup>
            </label>
            <input
              // style={{ backgroundColor: !isEmailValid ? "#F9B0B0" : "" }}
              disabled={verifyOtp}
              onChange={changeHandler}
              name="name"
              type="text"
              value={newMarketer.name}
              placeholder="First Name + Last Name"
              className="epass"
              // required
            />
            <label>
              USERNAME&nbsp;<sup className="mandatoryFieldSign">*</sup>
            </label>
            <input
              // style={{ backgroundColor: !isEmailValid ? "#F9B0B0" : "" }}
              disabled={verifyOtp}
              onChange={changeHandler}
              name="username"
              type="text"
              value={newMarketer.Username}
              placeholder="Choose a unique username"
              className="epass"
              // required
            />
            <label>
              Email ID&nbsp;<sup className="mandatoryFieldSign">*</sup>
            </label>
            <input
              // style={{ backgroundColor: !isEmailValid ? "#F9B0B0" : "" }}
              disabled={verifyOtp}
              onChange={changeHandler}
              name="email"
              type="email"
              value={newMarketer.email}
              placeholder="Enter your work email id"
              className="epass"
              // required
            />
            <label>Phone No</label>
            <input
              // style={{ backgroundColor: !isEmailValid ? "#F9B0B0" : "" }}
              disabled={verifyOtp}
              onChange={changeHandler}
              name="phoneNumber"
              type="text"
              value={newMarketer.phoneNumber}
              placeholder="Enter your Phone No"
              className="epass"
            />
            <label>
              Password&nbsp;<sup className="mandatoryFieldSign">*</sup>
            </label>
            <input
              // style={{ backgroundColor: !isPasswordValid && "#F9B0B0" }}
              disabled={verifyOtp}
              onChange={changeHandler}
              name="password"
              value={newMarketer.password}
              type="password"
              placeholder="Enter your password"
              className="epass"
              // required
            />
            <label>
              RE-ENTER PASSWORD&nbsp;<sup className="mandatoryFieldSign">*</sup>
            </label>
            <input
              // style={{ backgroundColor: !isPasswordValid && "#F9B0B0" }}
              disabled={verifyOtp}
              onChange={changeHandler}
              name="confirmPassword"
              value={newMarketer.confirmPassword}
              type="password"
              placeholder="Confirm your password"
              className="epass"
              // required
            />
            {verifyOtp && (
              <>
                <label style={{ color: "black" }}>ENTER OTP</label>
                <input
                  // style={{ backgroundColor: !isPasswordValid && "#F9B0B0" }}
                  onChange={changeHandler}
                  name="otp"
                  value={newMarketer.otp}
                  type="text"
                  placeholder="Enter Otp sent on your Email"
                  className="epass epass__otp"
                  // required
                />
              </>
            )}
            <button type="submit" className="login__btn">
              {!verifyOtp
                ? `${submitbtn ? "Registering" : "Register"}`
                : `${submitbtn ? "Verifying" : "Verify Account"}`}
            </button>
            <div className="or-divider">
              <span className="or-line"></span>
              <span className="or-text">or</span>
              <span className="or-line"></span>
            </div>
                 <div className="google-auth-container">
                         <div className="google-auth-container">
                           <div className="google-btn-wrapper">
                             <GoogleLogin
                               onSuccess={handleLoginSuccess}
                               onError={handleLoginError}
                               useOneTap
                               width="100%"
                               size="large"
                             />
                           </div>
                         </div>
                       </div>
            <div className="account_status">
              <p>
                Already Have an Account ?{" "}
                <span className="color-grey">
                  <b
                    onClick={() => {
                      window.location.href = "/user-login";
                    }}
                  >
                    {" "}
                    Login
                  </b>
                </span>{" "}
              </p>
              {/* <button type="button" class="login-with-google-btn" onClick={loginWithGoogle}>
                Sign in with Google
              </button> */}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default MarketersLogin;
