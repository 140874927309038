import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "../../../../../axios.js";
import "./CompanyLogo.css";
import uploadIcon from "../../../img/profile.png";
import TwoButtonWrapper from "../../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";

export const CompanyLogo = ({ setURLFunction, logo, style }) => {
  const [image, setImage] = useState(null);
  const [currentLogo, setCurrentLogo] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [cropper, setCropper] = useState();
  const [uploadStatus, setUploadStatus] = useState("Upload");
  const [entireFile, setEntireFile] = useState(null);

  // upload profile
  const AddedClass = {
    ...style,
  };
  console.log("the current logo is ", logo);
  useEffect(() => {
    if (typeof logo === "string" || logo instanceof String) {
      if (logo.length === 0) {
        setCurrentLogo(null);
      }
    } else {
      setCurrentLogo(logo);
    }
  }, [logo]);

  const uploadProfile = async (file) => {
    // let file = e.target.files[0];
    const data = new FormData();
    data.append("File", file);
    console.log(JSON.parse(localStorage.getItem("marketer")));
    const response = await axios.post("/get-doc-url", data, {
      headers: {
        authorization:
          "Bearer " + JSON.parse(localStorage.getItem("marketer")).token,
      },
    });

    setCurrentLogo(response?.data?.url);
    return response?.data?.url;
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    console.log("the shit is ", e.target.files[0]);
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      console.log("the reader result is ", reader.result);

      setImage(reader.result);
    };

    setImageName(e.target.files[0].name);
    setEntireFile(e.target.files[0]);
    reader.readAsDataURL(files[0]);
    e.target.value = null;
    console.log("baahar aa chuke hai");
  };

  const getCropData = async () => {
    try {
      const uploadedUrl = await uploadProfile(entireFile);
      setUploadStatus("Uploaded");
      setURLFunction(uploadedUrl);
      setEntireFile(null);
    } catch (error) {
      console.error("Upload error:", error);
      setUploadStatus("Upload");
      // Handle the error as needed
    }
  };

  const uploadButtonHandler = (field) => {
    console.log("Upload button clicked");
    let elem = document.getElementById("imageInput");
    console.log("Element retrieved:", elem);
    if (elem) {
      elem.click();
      console.log("Input element clicked");
    } else {
      console.log("Element not found");
    }
  };

  const handleEditButton = () => {
    setImage(null);
    setImageName(null);
    setCurrentLogo(null);
  };

  console.log("the image is ", image);
  console.log("the file is ", entireFile);
  console.log("the image name is ", imageName);
  console.log("the profile pic is ", currentLogo);

  return (
    <div className="generalPhotoContainer" style={AddedClass}>
      {currentLogo ? (
        <>
          <div className="uploadedContent">
            <img src={currentLogo} alt="" className="currentImage" />
            <p>{imageName}</p>
          </div>
          <div className="uploadLogo" onClick={handleEditButton}>
            <span className="material-symbols-outlined">edit</span>
          </div>
        </>
      ) : imageName && image ? (
        <>
          <div className="uploadedContent">
            <img src={image} alt="" className="currentImage" />
            <p>{imageName} </p>
          </div>
          <div className="uploadLogo" onClick={getCropData}>
            <span class="material-symbols-outlined">upload_file</span>
          </div>
        </>
      ) : (
        !image && (
          <>
            <div className="uploadedContent">
              <p>Upload the logo</p>
            </div>
            <div className="uploadLogo" onClick={uploadButtonHandler}>
              <span className="material-symbols-outlined">upload</span>
              <input
                id="imageInput"
                type="file"
                onChange={(e) => onChange(e)}
                // onChange={(e) => console.log("Input changed")}
                onClick={() => console.log("Input clicked")}
                accept=".jpeg,.png,.gif,.mov"
                style={{ display: "none" }}
              />
            </div>
          </>
        )
      )}
    </div>
  );
};
